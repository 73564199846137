/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, Navigate } from "react-router-dom";
import { isEqual } from "lodash";
import Masonry from "react-masonry-css";

import {
  deleteDashboardSection,
  getDashboardSections,
} from "../../../helpers/api";
import analytics from "../../../helpers/Analytics";
import revokeSharedDashboardSection from "../../../helpers/api/revokeSharedDashboardSection";
import getSharedDashboardSectionsWithUser from "../../../helpers/api/getSharedDashboardSectionsWithUser";
import updateSharedDashboardSectionInvitationStatus from "../../../helpers/api/updateSharedDashboardSectionInvitationStatus";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { SharedDashboardSectionStatus } from "../../../helpers/Enums";
import { DashboardSection } from "../../models/DashboardSection";
import { SharedCompanyAvatar } from "../../models/SharedCompanyAvatar";
import { UserDocument } from "../../models/UserDocument";
import { SharedDashboardSection } from "../../models/SharedDashboardSection";
import { CompanyAvatar } from "../../models/CompanyAvatar";
import stores from "../../stores";
import VoiceChatModal from "../ChatPage/voice-chat-modal/VoiceChatModal";

import { TheChiefDashboardLoading } from "./loading/TheChiefDashboardLoading";
import CustomSectionView from "./CustomSectionView";
import SharedDashboardSectionInvitationItem from "./SharedDashboardSectionInvitationItem";
import { AvatarCard } from "./AvatarCard";
import SharedSectionViewItem from "./SharedSectionViewItem";

type Props = {
  navigate: (path: string) => void;
};

type State = {
  sections: DashboardSection[];
  isEditing: boolean;
  isCreateNewSectionFlow: boolean;
  loading: boolean;
  loadingAvatarId?: number;
  showVoiceChatModal: boolean;
  userDocument?: UserDocument;
  selectedAvatar?: CompanyAvatar;
  sharedDashboardSections: SharedDashboardSection[];
  selectedFilter: string;
};

export default class TheChiefDashboard extends React.Component<Props, State> {
  private fetchIntervalId: NodeJS.Timeout | null = null;

  constructor(props: Props) {
    super(props);
    this.state = {
      sections: [],
      isEditing: false,
      isCreateNewSectionFlow: false,
      loading: true,
      loadingAvatarId: undefined,
      showVoiceChatModal: false,
      sharedDashboardSections: [],
      selectedFilter: "all",
    };
  }

  componentDidMount() {
    this.fetchSections();
    this.fetchSharedDashboardSections();
    stores.SectionStore.fetchsharedDashboardSectionsWithUser();
    this.fetchIntervalId = setInterval(this.fetchSections, 5000);
  }

  componentWillUnmount() {
    if (this.fetchIntervalId) {
      clearInterval(this.fetchIntervalId);
    }
  }

  private fetchSharedDashboardSections = async () => {
    try {
      const sharedSections = await getSharedDashboardSectionsWithUser(
        stores.userStore.currentUser.id
      );
      this.setState({ sharedDashboardSections: sharedSections });
    } catch (error) {
      console.error("Error fetching shared dashboard sections:", error);
    }
  };

  private fetchSections = async () => {
    try {
      if (stores.companyStore.selectedUserCompany) {
        const sections = await getDashboardSections(
          stores.companyStore.selectedUserCompany.id
        );

        if (!isEqual(this.state.sections, sections)) {
          this.setState({
            sections: sections,
            loading: false,
          });
        } else if (this.state.loading) {
          this.setState({ loading: false });
        }
      }
    } catch (error) {
      console.error("Error fetching sections:", error);
      this.setState({ loading: false });
    }
  };

  handleLayoutChange = (layout: any[]) => {};

  private handleDeleteSection = async (section: DashboardSection) => {
    analytics.trackEvent("delete_section_button_pressed", {
      category: "dashboard_event",
      label: `delete_section_${section.id}`,
    });

    this.setState({
      sections: this.state.sections.filter((s) => s.id !== section.id),
    });

    await deleteDashboardSection(section.id);
  };

  private handleDeleteSharedSection = async (
    sharedSection: SharedDashboardSection
  ) => {
    analytics.trackEvent("delete_shared_section_button_pressed", {
      category: "dashboard_event",
      label: `delete_shared_section_${sharedSection.id}`,
    });

    this.setState({
      sharedDashboardSections: this.state.sharedDashboardSections.filter(
        (s) => s.id !== sharedSection.id
      ),
    });

    await revokeSharedDashboardSection(sharedSection.id);
  };

  private handleCompleteOnboarding = async () => {
    analytics.trackEvent("onboarding_completed_button_pressed", {
      category: "onboarding_event",
      label: "complete_onboarding_button",
    });

    try {
      this.setState({ loading: true });

      stores.userStore.currentUser.is_onboarded = true;

      await stores.userStore.updateUserData();

      await this.fetchSections();
    } catch (error) {
      console.error("Error completing onboarding:", error);
      this.setState({ loading: false });
    }
  };

  // private handleCreateNewSectionFlow = async () => {
  //   analytics.trackEvent("create_new_section_pressed", {
  //     category: "dashboard_event",
  //     label: "create_new_section_button",
  //   });
  //   this.setState({ isCreateNewSectionFlow: true });
  //   stores.wizardStore.resetWizard();
  // };

  // private toggleEditMode = () => {
  //   this.setState((prevState) => ({ isEditing: !prevState.isEditing }));
  // };

  private startChatWithSharedAvatar = async (avatar: SharedCompanyAvatar) => {
    analytics.trackEvent("start_chat_with_avatar_pressed", {
      category: "dashboard_event",
      label: `chat_with_${avatar.company_avatar.name}`,
    });

    this.setState({ loadingAvatarId: avatar.company_avatar.id });

    stores.companyAvatarStore.selectedCompanyAvatar = avatar.company_avatar;

    if (!stores.companyStore.selectedUserCompany) {
      console.error("No company selected");
      this.setState({ loadingAvatarId: undefined });
      return;
    }

    const documentName = `Chat with ${avatar.company_avatar.name}`;

    try {
      const newDocument = await stores.userDocumentStore.createUserDocument(
        documentName
      );
      stores.userDocumentStore.selectedUserDocument = newDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newDocument.id;

      this.setState({ loadingAvatarId: undefined });

      this.props.navigate("/chat");
    } catch (error) {
      console.error("Failed to create chat document:", error);
      this.setState({ loadingAvatarId: undefined });
    }
  };

  private startTalkWithSharedAvatar = async (avatar: SharedCompanyAvatar) => {
    analytics.trackEvent("start_talk_with_avatar_pressed", {
      category: "dashboard_event",
      label: `talk_with_${avatar.company_avatar.name}`,
    });

    this.setState({ loadingAvatarId: avatar.company_avatar.id });

    stores.companyAvatarStore.selectedCompanyAvatar = avatar.company_avatar;

    if (!stores.companyStore.selectedUserCompany) {
      console.error("No company selected");
      this.setState({ loadingAvatarId: undefined });
      return;
    }

    const documentName = `Talk with ${avatar.company_avatar.name}`;

    try {
      const newDocument = await stores.userDocumentStore.createUserDocument(
        documentName
      );
      stores.userDocumentStore.selectedUserDocument = newDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newDocument.id;

      this.setState(
        {
          selectedAvatar: stores.companyAvatarStore.selectedCompanyAvatar,
          userDocument: newDocument,
        },
        () => this.setState({ showVoiceChatModal: true })
      );
    } catch (error) {
      console.error("Failed to create talk document:", error);
      this.setState({ loadingAvatarId: undefined });
    }
  };

  private handleAcceptSharedSection = async (
    section: SharedDashboardSection
  ) => {
    await updateSharedDashboardSectionInvitationStatus(section.id, "accepted");
    this.fetchSharedDashboardSections();
    stores.SectionStore.fetchsharedDashboardSectionsWithUser();
  };

  private handleRejectSharedSection = async (
    section: SharedDashboardSection
  ) => {
    await updateSharedDashboardSectionInvitationStatus(section.id, "rejected");
    this.fetchSharedDashboardSections();
    stores.SectionStore.fetchsharedDashboardSectionsWithUser();
  };

  private handleSelect = (value: string) => {
    this.setState({ selectedFilter: value });
  };

  private renderOnboardingPage = () => {
    const { name } = stores.userStore.currentUser || {};
    const userName = name || "User";

    const companyApps = stores.companyAppStore.companyApps;
    const companyModels = stores.companyModelStore.companyModels;

    const isAppConnected = companyApps && companyApps.length > 0;
    const isModelConnected = companyModels && companyModels.length > 0;

    if (this.state.loading) {
      return (
        <div className="card mb-5 mb-xl-10">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <TheChiefDashboardLoading />
          </div>
        </div>
      );
    }

    return (
      <div className="container py-10">
        <div className="row justify-content-center">
          <div className="col-md-8">
            <div className="card">
              <div className="card-body p-lg-5">
                <h2 className="fw-bold mb-7">Welcome {userName},</h2>
                <p className="fw-semibold fs-5 text-gray-800">
                  To start creating your dashboard, please complete the
                  following steps:
                </p>

                {/* Step 1: Model Integration */}
                <div className="d-flex align-items-center mb-5">
                  <div>
                    {isModelConnected ? (
                      <KTSVG
                        path="/media/icons/duotune/general/gen043.svg"
                        className="svg-icon-2hx svg-icon-success me-4"
                      />
                    ) : (
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-2hx svg-icon-gray-400 me-4"
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="fw-bold mb-1">
                      1) Integrate Your Data Model
                    </h4>
                    <p className="text-gray-600 mb-1">
                      Connect at least one CompanyModel by providing the
                      required information.
                    </p>
                    <Link
                      to="/models"
                      className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-2 mt-2"
                    >
                      <KTSVG
                        path="/media/icons/duotune/technology/teh003.svg"
                        className="svg-icon-3 me-2"
                      />
                      Manage Models
                    </Link>
                  </div>
                </div>

                {/* Step 2: App Integration */}
                <div className="d-flex align-items-center mb-5">
                  <div>
                    {isAppConnected ? (
                      <KTSVG
                        path="/media/icons/duotune/general/gen043.svg"
                        className="svg-icon-2hx svg-icon-success me-4"
                      />
                    ) : (
                      <KTSVG
                        path="/media/icons/duotune/general/gen040.svg"
                        className="svg-icon-2hx svg-icon-gray-400 me-4"
                      />
                    )}
                  </div>
                  <div>
                    <h4 className="fw-bold mb-1">
                      2) Integrate Your Application
                    </h4>
                    <p className="text-gray-600 mb-1">
                      Connect at least one CompanyApp from our app store to
                      enable data flow and interactivity.
                    </p>
                    <Link
                      to="/connections"
                      className="btn btn-sm btn-light btn-color-gray-800 btn-outline py-2 mb-2 mt-2"
                    >
                      <KTSVG
                        path="/media/icons/duotune/general/gen008.svg"
                        className="svg-icon-3 me-2"
                      />
                      Manage Connections
                    </Link>
                  </div>
                </div>

                <div className="text-center mt-10">
                  <button
                    className="btn btn-primary fs-6 px-8 py-3"
                    disabled={!isAppConnected || !isModelConnected}
                    onClick={this.handleCompleteOnboarding}
                  >
                    Complete Onboarding
                  </button>
                  <div className="form-text mt-4">
                    This button will be enabled once you have integrated at
                    least one model and one app.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      isCreateNewSectionFlow,
      sections,
      loading,
      showVoiceChatModal,
      userDocument,
      selectedAvatar,
      sharedDashboardSections,
    } = this.state;

    const currentUser = stores.userStore.currentUser;
    const isOnboarded = currentUser?.is_onboarded;
    const acceptedSharedDashboardSections = sharedDashboardSections.filter(
      (section) =>
        section.invitation_status === SharedDashboardSectionStatus.Accepted
    );

    const pendingSharedDashboardSections = sharedDashboardSections.filter(
      (section) =>
        section.invitation_status === SharedDashboardSectionStatus.Pending
    );

    if (loading) {
      return (
        <div className="card mb-5 mb-xl-10">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "80vh" }}
          >
            <TheChiefDashboardLoading />
          </div>
        </div>
      );
    }

    if (isCreateNewSectionFlow) {
      return <Navigate to="/create-new-section-flow" />;
    }

    if (!isOnboarded) {
      return this.renderOnboardingPage();
    }
    return (
      <>
        {pendingSharedDashboardSections.length > 0
          ? pendingSharedDashboardSections.map((section) => (
              <SharedDashboardSectionInvitationItem
                key={`shared-section-${section.id}`}
                onAcceptPress={this.handleAcceptSharedSection}
                onRejectPress={this.handleRejectSharedSection}
                sharedSection={section}
              />
            ))
          : null}

        <div className="d-flex justify-content-end align-items-center mb-4">
          <span className="fw-bold fs-6 me-2 mt-2">Dashboard Sections:</span>

          <button
            className="btn btn-light dropdown-toggle"
            type="button"
            id="dropdownMenuButton"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            style={{ width: "200px" }}
          >
            {this.state.selectedFilter === "my-sections"
              ? "My Sections"
              : this.state.selectedFilter === "shared-sections"
              ? "Shared Sections"
              : "All Sections"}
          </button>

          <div
            className="dropdown-menu"
            aria-labelledby="dropdownMenuButton"
            style={{ width: "200px" }}
          >
            <a
              className="dropdown-item"
              href="#"
              onClick={() => this.handleSelect("all")}
            >
              All Sections
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => this.handleSelect("my-sections")}
            >
              My Sections
            </a>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => this.handleSelect("shared-sections")}
            >
              Shared Sections
            </a>
          </div>
        </div>

        {stores.companyAvatarStore.sharedAvatarsWithUser.length > 0 ||
        acceptedSharedDashboardSections.length > 0 ||
        sections.length > 0 ? (
          <>
            {stores.companyAvatarStore.sharedAvatarsWithUser.length > 0 && (
              <>
                <span className="fw-bolder text-dark fs-3 ps-2">
                  Shared Avatars
                </span>

                <Masonry
                  breakpointCols={{ default: 4, 2350: 3, 1800: 2, 1000: 1 }}
                  className="masonry-grid"
                  columnClassName="masonry-grid-column"
                  style={{
                    display: "flex",
                    marginLeft: "-16px",
                    width: "auto",
                  }}
                >
                  {stores.companyAvatarStore.sharedAvatarsWithUser.map(
                    (avatarData) => (
                      <div
                        key={`avatar-${avatarData.id}`}
                        style={{
                          marginBottom: "16px",
                          paddingLeft: "16px",
                          marginTop: "8px",
                        }}
                      >
                        <AvatarCard
                          isChatPreparing={!!this.state.loadingAvatarId}
                          avatar={avatarData}
                          onStartChatWithAvatarPress={
                            this.startChatWithSharedAvatar
                          }
                          onStartTalkWithAvatarPress={
                            this.startTalkWithSharedAvatar
                          }
                        />
                      </div>
                    )
                  )}
                </Masonry>
                <div className="separator mb-5 opacity-75"></div>
              </>
            )}

            <span className="fw-bolder text-dark mb-8 fs-3 ps-2">Sections</span>

            {this.state.selectedFilter === "shared-sections" &&
              acceptedSharedDashboardSections.length === 0 && (
                <p className="text-center text-muted">
                  No shared sections available.
                </p>
              )}

            {this.state.selectedFilter === "my-sections" &&
              sections.length === 0 && (
                <p className="text-center text-muted">
                  No your sections available.
                </p>
              )}
            {this.state.sections.length > 0 ||
            acceptedSharedDashboardSections.length > 0 ? (
              <Masonry
                breakpointCols={{ default: 4, 2350: 3, 1800: 2, 1000: 1 }}
                className="masonry-grid"
                columnClassName="masonry-grid-column"
                style={{
                  display: "flex",
                  marginLeft: "-16px",
                  marginTop: "8px",
                  width: "auto",
                }}
              >
                {(this.state.selectedFilter === "all" ||
                  this.state.selectedFilter === "my-sections") &&
                  this.state.sections.map((section) => (
                    <div
                      key={section.id}
                      style={{ marginBottom: "16px", paddingLeft: "16px" }}
                    >
                      <CustomSectionView
                        section={section}
                        onDeletePress={this.handleDeleteSection}
                      />
                    </div>
                  ))}

                {(this.state.selectedFilter === "all" ||
                  this.state.selectedFilter === "shared-sections") &&
                  acceptedSharedDashboardSections.map((sharedSection) => (
                    <div
                      key={sharedSection.id}
                      style={{ marginBottom: "16px", paddingLeft: "16px" }}
                    >
                      <SharedSectionViewItem
                        sharedSection={sharedSection}
                        onDeletePress={this.handleDeleteSharedSection}
                      />
                    </div>
                  ))}
              </Masonry>
            ) : this.state.selectedFilter === "all" ? (
              <p className="text-center text-muted">No sections available.</p>
            ) : null}
          </>
        ) : (
          <div className="container py-10 mt-10">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="card">
                  <div className="card-body p-lg-5 d-flex flex-row align-items-center">
                    <div className="symbol symbol-80px mb-2 me-4">
                      <img
                        alt="Pic"
                        src={toAbsoluteUrl(
                          "https://imagedelivery.net/01kSRcVEVAYmOHOBQNSt0A/93bbf41d-9c33-44c7-b6eb-38fe5b9a1300/mid"
                        )}
                      />
                    </div>

                    <p className="text-gray-600 mb-1">
                      🤖 The Chief is working diligently to set up your
                      dashboard and will have it ready for you shortly. Now is
                      the perfect time to grab a coffee and let the AI handle
                      the rest! ☕
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {selectedAvatar ? (
          <VoiceChatModal
            isOpen={showVoiceChatModal}
            onClose={() => {
              this.setState({
                showVoiceChatModal: false,
                loadingAvatarId: undefined,
              });
            }}
            selectedMode={"avatar"}
            userDocument={userDocument}
            companyId={selectedAvatar.company_id}
            companyAvatar={selectedAvatar}
            companyAvatarName={selectedAvatar.name}
          />
        ) : null}
      </>
    );
  }
}
