import CompanyAppStore from "./CompanyAppStore";
import CompanyModelStore from "./CompanyModelStore";
import CompanyStorageStore from "./CompanyStorageStore";
import CompanyStore from "./CompanyStore";
import CompanyUserStore from "./CompanyUserStore";
import UserActionLogStore from "./UserActionLogStore";
import UserDocumentStore from "./UserDocumentStore";
import UserRoleStore from "./UserRoleStore";
import UserStore from "./UserStore";
import CompanyAvatarStore from "./CompanyAvatarStore";
import WizardStore from "./WizardStore";
import CompanyDepartmentStore from "./CompanyDepartmentStore";
import companyReportsStore from "./CompanyReportsStore";
import SectionStore from "./SectionStore";

export default class RootStore {
  userStore: UserStore;
  companyStore: CompanyStore;
  userRoleStore: UserRoleStore;
  companyUserStore: CompanyUserStore;
  companyAppStore: CompanyAppStore;
  companyModelStore: CompanyModelStore;
  companyStorageStore: CompanyStorageStore;
  userActionLogStore: UserActionLogStore;
  userDocumentStore: UserDocumentStore;
  companyAvatarStore: CompanyAvatarStore;
  wizardStore: WizardStore;
  companyDepartmentStore: CompanyDepartmentStore;
  companyReportsStore: companyReportsStore;
  SectionStore: SectionStore;

  constructor() {
    this.userStore = new UserStore(this);
    this.companyStore = new CompanyStore(this);
    this.userRoleStore = new UserRoleStore(this);
    this.companyUserStore = new CompanyUserStore(this);
    this.companyAppStore = new CompanyAppStore(this);
    this.companyModelStore = new CompanyModelStore(this);
    this.companyStorageStore = new CompanyStorageStore(this);
    this.userActionLogStore = new UserActionLogStore(this);
    this.userDocumentStore = new UserDocumentStore(this);
    this.companyAvatarStore = new CompanyAvatarStore(this);
    this.wizardStore = new WizardStore(this);
    this.companyDepartmentStore = new CompanyDepartmentStore(this);
    this.companyReportsStore = new companyReportsStore(this);
    this.SectionStore = new SectionStore(this);
  }

  logout() {}
}
