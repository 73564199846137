import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { SharedDashboardSection } from "../../app/models/SharedDashboardSection";
import Functions from "../Functions";

export default async function getSharedDashboardSectionsWithUsers(
  dashboardSectionId: number
): Promise<SharedDashboardSection[]> {
  const client = ApolloClientHelper.getApolloClient();

  const query = gql`
    query SharedDashboardSectionsWithUsersQuery($dashboardSectionId: Int!) {
      sharedSectionsWithUsers(dashboardSectionId: $dashboardSectionId) {
        id
        dashboard_section_id
        shared_by_user_id
        shared_with_user_id
        invitation_status
        created_at
        updated_at
        dashboard_section {
          id
          company_id
          section_name
          section_description
          is_manager_section
          order
          section_preferences
          view
          suggestion_view
          nodes
          edges
        }
        shared_by_user {
          id
          name
          email
        }
        shared_with_user {
          id
          name
          email
        }
      }
    }
  `;

  try {
    const { data } = await client.query({
      query,
      variables: { dashboardSectionId },
      fetchPolicy: "no-cache",
    });

    const sharedSections: SharedDashboardSection[] = get(
      data,
      "sharedSectionsWithUsers",
      []
    );

    sharedSections.forEach((sharedSection) => {
      if (sharedSection.dashboard_section) {
        sharedSection.dashboard_section.node_objects_list =
          Functions.parseNodesAndEdgesString(
            sharedSection.dashboard_section.nodes
          );

        sharedSection.dashboard_section.edge_objects_list =
          Functions.parseNodesAndEdgesString(
            sharedSection.dashboard_section.edges
          );
      }
    });

    return sharedSections;
  } catch (error) {
    console.error(error);
    throw error;
  }
}
