import React, { useEffect, useState } from "react";
import JsxParser from "react-jsx-parser";
import { useNavigate } from "react-router-dom";
import { Spinner } from "react-bootstrap";

import { KTSVG } from "../../../helpers";
import {
  ChartsWidget1,
  ListsWidget1,
  MixedWidget1,
  StatisticsWidget1,
  TablesWidget1,
  SuggestionsWidget1,
  CalendarWidget1,
} from "../../../partials/widgets";
import getSharedDashboardSectionsWithUsers from "../../../helpers/api/getSharedDashboardSectionsWithUsers";
import analytics from "../../../helpers/Analytics";
import { DashboardSection } from "../../models/DashboardSection";
import { SharedDashboardSection } from "../../models/SharedDashboardSection";
import stores from "../../stores";
import { CustomSectionViewActionModal } from "./CustomSectionViewActionModal";

type Props = {
  section: DashboardSection;
  onDeletePress: (section: DashboardSection) => void;
};

const CustomSectionView: React.FC<Props> = ({ section, onDeletePress }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [sharedUsers, setSharedUsers] = useState<SharedDashboardSection[]>([]);

  useEffect(() => {
    const fetchSharedUsers = async () => {
      if (!section.id) return;

      try {
        const users = await getSharedDashboardSectionsWithUsers(
          Number(section.id)
        );
        setSharedUsers(users);
      } catch (error) {
        console.error("Error fetching shared users:", error);
      } finally {
      }
    };

    fetchSharedUsers();
  }, [section]);

  const onTalkPress = async () => {
    analytics.trackEvent("ask_to_chief_pressed", {
      category: "dashboard_event",
      label: "ask_to_chief_button",
    });
    stores.userDocumentStore.selectedDashboardSection = section;

    try {
      const documentName = section.section_name;

      if (!stores.companyStore.selectedUserCompany) {
        throw new Error("No company selected.");
      }

      setIsLoading(true);

      const newDocument = await stores.userDocumentStore.createUserDocument(
        documentName
      );

      stores.userDocumentStore.selectedUserDocument = newDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newDocument.id;

      setTimeout(() => {
        navigate("/chat", {
          state: {
            selectedAvatarId: undefined,
            isTheChief: true,
          },
        });
      }, 300);
    } catch (error) {
      console.error("Failed to create chat document:", error);
    }
  };

  const onEditPress = () => {
    analytics.trackEvent("edit_section_button_pressed", {
      category: "dashboard_event",
      label: "edit_section_button",
    });

    navigate(`/create-new-section-flow/${section.id}`);
  };

  const onDeletePressHandler = () => {
    analytics.trackEvent("delete_section_button_pressed", {
      category: "dashboard_event",
      label: "delete_section_button",
    });

    onDeletePress(section);
  };

  const handleOpenShareModal = () => {
    analytics.trackEvent("share_section_pressed", {
      category: "dashboard_section_events",
      label: "share_section_button",
    });
    stores.SectionStore.isSharedSectionModalOpen = true;
    stores.SectionStore.selectedSection = section;
  };

  const questionNode = section.node_objects_list?.find(
    (node) => node.type === "questionNode"
  );

  const questionText: any = questionNode?.data?.text;

  return (
    <div className="card card-xxl-stretch mb-5 mb-xl-8 border border-gray-300 shadow-xs">
      <div className="card-header border-0 pt-5 d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          {sharedUsers.length > 0 ? (
            <span className="text-muted fw-semibold">
              Shared with {sharedUsers.length} person
              {sharedUsers.length !== 1 ? "s" : ""}
            </span>
          ) : null}
        </div>

        <div className="card-toolbar d-flex align-items-center">
          <button
            className="btn btn-sm btn-light-primary p-4 me-4"
            onClick={handleOpenShareModal}
          >
            <KTSVG
              path="/media/icons/duotune/coding/cod008.svg"
              className="svg-icon-2 me-2"
            />
            Share
          </button>

          <button
            className="btn btn-sm btn-light-primary p-4 me-4"
            onClick={onTalkPress}
            disabled={isLoading || !section.view}
          >
            <KTSVG
              path="/media/icons/duotune/communication/com012.svg"
              className="svg-icon-1 me-2"
            />
            {isLoading || !section.view
              ? "The Chief is preparing.."
              : "Ask to Chief"}
          </button>

          <button
            className="btn btn-sm btn-icon p-5"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
            onClick={() =>
              analytics.trackEvent("more_options_pressed", {
                category: "dashboard_event",
                label: "more_options_button",
              })
            }
          >
            <i className="bi bi-three-dots fs-2"></i>
          </button>

          <CustomSectionViewActionModal
            section={section}
            onDeletePress={onDeletePressHandler}
            onUpdatePress={onEditPress}
          />
        </div>
      </div>

      <div className="card-header border-0 py-2 mb-2 d-block">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label fw-bold fs-3 mb-1">
            {section.section_name}
          </span>
        </h3>

        {questionText ? (
          <span className="card-label fs-6 mb-1">{questionText}</span>
        ) : null}
      </div>

      <div className="card-body py-0">
        <div
          className="bg-body"
          style={{ marginLeft: -10, marginRight: -10, borderRadius: 5 }}
        >
          {section.view ? (
            <JsxParser
              bindings={{
                foo: "bar",
                myEventHandler: () => {
                  /* ... do stuff ... */
                },
              }}
              components={{
                ChartsWidget1: ChartsWidget1 as any,
                ListsWidget1: ListsWidget1 as any,
                StatisticsWidget1: StatisticsWidget1 as any,
                MixedWidget1: MixedWidget1 as any,
                TablesWidget1: TablesWidget1 as any,
                CalendarWidget1: CalendarWidget1 as any,
              }}
              jsx={section.view}
            />
          ) : (
            <div className="d-flex flex-row p-4 mb-5">
              <Spinner
                className="spinner-grow-sm text-secondary"
                style={{ width: 15, height: 15, marginRight: 2 }}
                animation="grow"
                role="status"
              />

              <p className="text-gray-600 mt-4 mb-4">
                🤖 The Chief is working diligently to set up your section and
                will have it ready for you shortly.
              </p>
            </div>
          )}
        </div>

        <div
          className="bg-body"
          style={{
            marginLeft: -10,
            marginRight: -10,
            borderRadius: 5,
          }}
        >
          {section.suggestion_view ? (
            <JsxParser
              bindings={{
                foo: "bar",
                myEventHandler: () => {
                  /* ... do stuff ... */
                },
              }}
              components={{
                ChartsWidget1: ChartsWidget1 as any,
                ListsWidget1: ListsWidget1 as any,
                StatisticsWidget1: StatisticsWidget1 as any,
                MixedWidget1: MixedWidget1 as any,
                TablesWidget1: TablesWidget1 as any,
                SuggestionsWidget1: SuggestionsWidget1 as any,
              }}
              jsx={section.suggestion_view}
            />
          ) : null}
        </div>
      </div>

      <div className="card-header border-0 pt-5 pb-5 align-self-end">
        <div className="card-toolbar">
          <button
            className="btn btn-sm btn-light-primary p-4 me-4"
            onClick={onTalkPress}
            disabled={isLoading || !section.view}
          >
            <KTSVG
              path="/media/icons/duotune/communication/com012.svg"
              className="svg-icon-1 me-2"
            />
            {isLoading || !section.view
              ? "The Chief is preparing.."
              : "Ask to Chief"}
          </button>

          <button
            className="btn btn-sm btn-icon p-5"
            data-kt-menu-trigger="click"
            data-kt-menu-placement="bottom-end"
            data-kt-menu-flip="top-end"
          >
            <i className="bi bi-three-dots fs-2"></i>
          </button>

          <CustomSectionViewActionModal
            section={section}
            onDeletePress={onDeletePressHandler}
            onUpdatePress={onEditPress}
          />
        </div>
      </div>
    </div>
  );
};

export default CustomSectionView;
