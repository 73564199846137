// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { SharedDashboardSection } from "../../app/models/SharedDashboardSection";

export default async function shareDashboardSection(
  dashboardSectionId: number,
  sharedByUserId: number,
  sharedWithUserId: number
): Promise<SharedDashboardSection> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
    mutation {
      shareDashboardSection(
        dashboardSectionId: ${dashboardSectionId}
        sharedByUserId: ${sharedByUserId}
        sharedWithUserId: ${sharedWithUserId}
      ) {
        id
        dashboard_section_id
        shared_by_user_id
        shared_with_user_id
        invitation_status
        created_at
        updated_at
        dashboard_section {
          id
          company_id
          section_name
          section_description
          is_manager_section
          order
          section_preferences
          view
          suggestion_view
          nodes
          edges
        }
        shared_by_user {
          id
          name
          email
        }
        shared_with_user {
          id
          name
          email
        }
      }
    }
  `;

    client
      .mutate({ mutation })
      .then((shareDashboardSectionMutationResult: any) => {
        const sharedSection: SharedDashboardSection = get(
          shareDashboardSectionMutationResult,
          "data.shareDashboardSection",
          null
        );

        if (sharedSection) {
          resolve(sharedSection);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);
        reject();
      });
  });
}
