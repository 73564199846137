import { injectIntl, WrappedComponentProps } from "react-intl";
import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { PageLink, PageTitle } from "../../../layout/core";
import stores from "../../stores";

import TheChiefDashboard from "./TheChiefDashboard";
import { SharedSectionModal } from "./SharedSectionModal";

const TheChiefDashboardBreadCrumbs: Array<PageLink> = [
  {
    title: "Home",
    path: "/the-chief-workspace",
    isSeparator: false,
    isActive: false,
  },
];

const TheChiefDashboardWrapper = (props: WrappedComponentProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle breadcrumbs={TheChiefDashboardBreadCrumbs}>
        {props.intl.formatMessage({ id: "MENU.THECHIEFDASHBOARD" })}
      </PageTitle>

      <TheChiefDashboard navigate={navigate} />

      {stores.SectionStore.isSharedSectionModalOpen && <SharedSectionModal />}
    </>
  );
};

const TheChiefDashboardWrapperWithIntl = injectIntl(
  observer(TheChiefDashboardWrapper)
);

export { TheChiefDashboardWrapperWithIntl as TheChiefDashboardWrapper };
