/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { MentionsInput, Mention, SuggestionDataItem } from "react-mentions";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import StreamingAvatar, {
  StreamingEvents,
  TaskType,
  SpeakRequest,
} from "@heygen/streaming-avatar";

import {
  chat,
  chatAvatar,
  chatGeneral,
  createDocumentMessage,
  deleteDocumentMessage,
  getAvatar,
  getCompanyUserDepartments,
  getDocumentMessages,
  getSectionKpiQuestionReportsByDashboard,
  stopChat,
  updateDocumentMessage,
} from "../../../helpers/api";
import Functions from "../../../helpers/Functions";
import {
  ConversationMode,
  CompanyAppStatus,
  FeatureType,
  CompanyIndexStatus,
} from "../../../helpers/Enums";
import SocketHelper from "../../../helpers/SocketHelper";
import ExportHelper from "../../../helpers/ExportHelper";
import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { UserDocument } from "../../models/UserDocument";
import { DocumentMessage } from "../../models/DocumentMessage";
import { CompanyModel } from "../../models/CompanyModel";
import { CompanyApp } from "../../models/CompanyApp";
import { CompanyAvatar } from "../../models/CompanyAvatar";
import i18n from "../../../i18n";
import { ChatResult } from "../../models/ChatResult";
import stores from "../../stores";

import MessageItem from "./MessageItem";
import { DashboardSection } from "../../models/DashboardSection";
import VoiceChatModal from "./voice-chat-modal/VoiceChatModal";
import analytics from "../../../helpers/Analytics";

interface MentionItem {
  id: string;
  display: string;
}

interface IStartAvatarRequest {
  avatarName: string;
  newSessionRequest: {
    quality: string;
    voice: {
      voiceId: string;
    };
    iceServers?: RTCIceServer[];
  };
}

interface State {
  chatUpdateFlag: boolean;
  message: string;
  messages: DocumentMessage[];
  isLoading: boolean;
  isAIReady: boolean;
  userDocument: UserDocument;
  selectedCompanyAppIds: string[];
  selectedCompanyModel?: CompanyModel;
  selectedCompanyAvatar?: CompanyAvatar;
  recording: boolean;
  avatar: StreamingAvatar | undefined;
  avatarSessionData: any;
  avatarStream: MediaStream | null;
  conversationMode: ConversationMode;
  filteredCompanyApps: CompanyApp[];
  canUseTheChief: boolean;
  canUseLiveAvatar: boolean;
  isElectron: boolean;
  refreshTime: number;
  appId?: number;
  avatarId?: number;
  modelId?: number;
  mentions: MentionItem[];
  startNewChat: boolean;
  isWebSearchActive: boolean;
  isThinkDeeplyLoading: boolean;
  avatarError: string | null;
  isVideoChatActive: boolean;
  isVoiceChatActive: boolean;
  showVoiceChatModal: boolean;
  isVoiceAskActive: boolean;
  voiceAskTranscript: string;
  subtitles: string;
  loadingMessage: string;
  ignoreSilenceCheck: boolean;
  loadingMessages: string[];
  currentLoadingMessageIndex: number;
  originalCopiedMessage: string;
  isCopied: boolean;
  reportString: string | undefined;
  isConnecting: boolean;
  isAvatarSpeaking: boolean;
}

interface Props {
  mentionInputId?: string;
  downloadButtonId?: string;
  webSearchButtonId?: string;
  userDocument: UserDocument;
  selectedCompanyModel?: CompanyModel;
  selectedCompanyAvatar?: CompanyAvatar;
  canUseLiveAvatar: boolean;
  canUseTheChief: boolean;
  dashboardSection?: DashboardSection;
}

@observer
export default class ChatInner extends React.Component<Props, State> {
  private messagesEndRef: any;
  private messagesContainerRef: React.RefObject<HTMLDivElement>;
  private avatarTimeoutId: any;
  private refreshTimeoutId: any;
  private checkTokenUsageTimeoutId: any;
  private mediaStream: HTMLVideoElement | null = null;

  private voiceAskRecognition: SpeechRecognition;
  private videoChatRecognition: SpeechRecognition;

  private voiceAskSilenceTimeoutId: any = null;
  private videoChatSilenceTimeoutId: any = null;

  private isVideoChatRecognitionActive: boolean = false;
  private loadingIntervalId: any = null;

  private isUserScrolling: boolean = false;

  constructor(props: Props) {
    super(props);

    const SpeechRecognitionClass =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    if (!SpeechRecognitionClass) {
      toast.error(i18n.ToastMessages.speechRecognitionNotSupported, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
    this.voiceAskRecognition = new SpeechRecognitionClass();
    this.videoChatRecognition = new SpeechRecognitionClass();

    this.handleVideoChatRecord = this.handleVideoChatRecord.bind(this);

    this.messagesContainerRef = React.createRef();

    this.state = {
      chatUpdateFlag: false,
      message: "",
      messages: [],
      isLoading: true,
      isAIReady: true,
      userDocument: this.props.userDocument,
      selectedCompanyAppIds: [],
      selectedCompanyAvatar: this.props.selectedCompanyAvatar,
      selectedCompanyModel: this.props.selectedCompanyModel,
      recording: false,
      avatar: undefined,
      avatarSessionData: undefined,
      avatarStream: null,
      conversationMode: ConversationMode.Chat,
      filteredCompanyApps: [],
      canUseTheChief: this.props.canUseTheChief,
      canUseLiveAvatar: this.props.canUseLiveAvatar,
      isElectron: false,
      refreshTime: 0,
      mentions: [],
      startNewChat: false,
      isWebSearchActive: false,
      isThinkDeeplyLoading: false,
      avatarError: null,
      isVideoChatActive: false,
      isVoiceChatActive: false,
      showVoiceChatModal: false,
      isVoiceAskActive: false,
      voiceAskTranscript: "",
      subtitles: "",
      loadingMessage: "",
      ignoreSilenceCheck: false,
      loadingMessages: [
        "Loading...",
        "Processing your message...",
        "Connecting to the system...",
        "Fetching data...",
        "Preparing your response...",
      ],
      currentLoadingMessageIndex: 0,
      originalCopiedMessage: "",
      isCopied: false,
      reportString: undefined,
      isConnecting: false,
      isAvatarSpeaking: false,
    };
  }

  async componentWillMount(): Promise<void> {
    const isWebSearchActive =
      localStorage.getItem("isWebSearchActive") === "true";
    this.setState({ isWebSearchActive });

    this.setState({
      isElectron: window.electronAPIs?.isElectron ?? false,
      userDocument: this.props.userDocument,
      canUseTheChief: this.props.canUseTheChief,
      canUseLiveAvatar: this.props.canUseLiveAvatar,
      selectedCompanyModel: this.props.selectedCompanyModel,
      selectedCompanyAvatar: this.props.selectedCompanyAvatar,
    });

    SocketHelper.addMessageHandler(this.handleMessage);

    this.getFilteredCompanyApps();

    this.getMessages();

    this.getDashboardSectionReports();

    this.setState({ isAIReady: true });
    stores.companyAvatarStore.isAvatarLoading = false;

    if (this.state.avatarStream) {
      const tracks = this.state.avatarStream.getTracks();

      tracks.forEach((track) => {
        track.addEventListener("ended", () => {});
      });
    }

    if (this.messagesContainerRef.current) {
      this.messagesContainerRef.current.addEventListener(
        "scroll",
        this.handleScroll
      );
    }
  }

  componentWillUnmount(): void {
    if (this.refreshTimeoutId) {
      clearInterval(this.refreshTimeoutId);
    }
    SocketHelper.removeMessageHandler(this.handleMessage);

    if (this.voiceAskRecognition) {
      this.voiceAskRecognition.stop();
    }
    if (this.videoChatRecognition) {
      this.videoChatRecognition.stop();
    }
    if (this.videoChatSilenceTimeoutId) {
      clearTimeout(this.videoChatSilenceTimeoutId);
    }
    if (this.loadingIntervalId) {
      clearInterval(this.loadingIntervalId);
    }

    this.disconnectMediaStream();

    if (this.messagesContainerRef.current) {
      this.messagesContainerRef.current.removeEventListener(
        "scroll",
        this.handleScroll
      );
    }
  }

  componentWillReceiveProps(nextProps: Props) {
    const isUserDocumentChanged =
      this.state.userDocument.id !== nextProps.userDocument.id;

    if (
      this.props.userDocument.id !== nextProps.userDocument.id ||
      this.props.canUseTheChief !== nextProps.canUseTheChief ||
      this.props.canUseLiveAvatar !== nextProps.canUseLiveAvatar ||
      this.props.selectedCompanyModel !== nextProps.selectedCompanyModel ||
      this.props.selectedCompanyAvatar !== nextProps.selectedCompanyAvatar
    ) {
      this.setState(
        {
          userDocument: nextProps.userDocument,
          canUseTheChief: nextProps.canUseTheChief,
          canUseLiveAvatar: nextProps.canUseLiveAvatar,
          selectedCompanyModel: nextProps.selectedCompanyModel,
          selectedCompanyAvatar: nextProps.selectedCompanyAvatar,
        },
        () => {
          if (isUserDocumentChanged) {
            this.getMessages();
          }
        }
      );
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (
      prevState.messages.length !== this.state.messages.length &&
      !this.isUserScrolling
    ) {
      this.scrollToBottom();
    }
  }

  private handleMessage = (message: any) => {
    const {
      messages,
      userDocument,
      avatar,
      avatarSessionData,
      conversationMode,
    } = this.state;

    if (message.processId === 1) {
      if (this.checkTokenUsageTimeoutId) {
        clearTimeout(this.checkTokenUsageTimeoutId);
      }

      this.checkTokenUsageTimeoutId = setTimeout(async () => {
        stores.companyStore.checkCompanyFreeToken();
      }, 3000);

      if (message.type === "received_event") {
        this.resetSilenceTimer(false);
      }

      if (userDocument.id === message.userDocumentId) {
        if (messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
          if (lastMessage) {
            lastMessage.message_reply = Functions.decodeMessage(
              message.streamedResult
            );

            this.toggleChatUpdateFlag();

            const shouldScroll = this.isScrolledToBottom();
            if (shouldScroll) {
              if (this.messagesEndRef) {
                this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
              }
            }

            if (conversationMode === ConversationMode.Avatar) {
              if (this.avatarTimeoutId) {
                clearTimeout(this.avatarTimeoutId);
              }

              this.avatarTimeoutId = setTimeout(async () => {
                if (!avatar || !avatarSessionData) {
                  return;
                }

                const decodedMessage = Functions.decodeMessage(
                  lastMessage.message_reply
                );

                stores.companyAvatarStore.isAvatarLoading = false;

                await this.textToSpeechAvatar(decodedMessage);
              }, 2000);
            } else {
              stores.companyAvatarStore.isAvatarLoading = false;
            }
          }
        }
      }
    } else if (message.processId === 7) {
      if (userDocument.id === message.userDocumentId) {
        if (messages.length > 0) {
          const lastMessage = messages[messages.length - 1];
          if (lastMessage) {
            lastMessage.suggested_questions = Functions.completeArrayFromString(
              Functions.decodeMessage(message.streamedResult)
            );

            const shouldScroll = this.isScrolledToBottom();
            if (shouldScroll) {
              if (this.messagesEndRef) {
                this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
              }
            }
          }
        }
      }
    }
  };

  private handleSpeechToAvatar = async (message: string) => {
    await this.handleConversationModeChange(ConversationMode.Avatar);
    this.textToSpeechAvatar(message);
  };

  private textToSpeechAvatar(text: string): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { avatar } = this.state;
      if (!avatar) {
        reject("Avatar is undefined.");
        return;
      }

      this.setState({ isAvatarSpeaking: true });

      const parts: string[] = [];
      for (let i = 0; i < text.length; i += 500) {
        parts.push(text.substring(i, i + 500));
      }

      for (const part of parts) {
        if (part.trim() === "") {
          continue;
        }
        try {
          const speakRequest: SpeakRequest = {
            text: part,
            task_type: TaskType.REPEAT,
          };

          try {
            await avatar.speak(speakRequest);
          } catch (err) {
            console.error("Avatar speak error:", err);
            reject(err);
            return;
          }
        } catch (err) {
          console.error("Avatar speak error:", err);
          reject(err);
          return;
        }
      }

      this.setState({ isAvatarSpeaking: false });

      resolve(true);
    });
  }

  private handleConversationModeChange = async (mode: ConversationMode) => {
    if (this.state.conversationMode === mode) {
      return;
    }

    const canUseAvatar = await stores.userStore.checkSubscribedFeatureType(
      FeatureType.LiveAvatar
    );

    if (this.state.isVideoChatActive || this.state.isVoiceAskActive) {
      toast.warn(i18n.ToastMessages.cannotChangeModeVoiceActive, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (mode === ConversationMode.Chat) {
      if (this.state.avatar && this.state.avatarSessionData) {
        await this.stopAvatar();
      }
    }

    analytics.trackEvent("change_conversation_mode", {
      category: "chat_events",
      label: "change_conversation_mode_button",
      mode: mode === ConversationMode.Chat ? "chat" : "avatar",
    });

    if (mode === ConversationMode.Avatar && !canUseAvatar) {
      toast.error(i18n.ToastMessages.liveAvatarError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.setState({ conversationMode: mode }, async () => {
      if (
        mode === ConversationMode.Avatar &&
        canUseAvatar &&
        this.state.canUseLiveAvatar
      ) {
        try {
          await this.getAvatar();
        } catch (error) {}
      } else if (mode === ConversationMode.Chat) {
        if (this.state.avatar && this.state.avatarSessionData) {
          await this.stopAvatar();
        }
      }
    });
  };

  private getFilteredCompanyApps = async () => {
    const companyUser = stores.companyUserStore.companyUsers.find(
      (user) =>
        user.user_id.toString() === stores.userStore.currentUser.id.toString()
    );

    if (companyUser) {
      const companyuserDepartment = await getCompanyUserDepartments(
        companyUser?.id
      );
      const departmentIds = companyuserDepartment.map(
        (item) => item.department_id
      );

      const matchingAppIds = new Set(
        stores.companyAppStore.appDepartments
          .filter((appDept: { department_id: number }) =>
            departmentIds.includes(appDept.department_id)
          )
          .map((appDept: { app_id: any }) => appDept.app_id)
      );

      const filteredCompanyApps = stores.companyAppStore.companyApps.filter(
        (app: CompanyApp) => matchingAppIds.has(app.app_id)
      );

      this.setState({ filteredCompanyApps });
    }
  };

  private getMessages = async () => {
    const { userDocument } = this.state;
    this.setState({ isLoading: true });
    const messages = await getDocumentMessages(userDocument.id);

    this.setState({ messages, isLoading: false }, () => {
      const shouldScroll = this.isScrolledToBottom();
      if (shouldScroll && this.messagesEndRef) {
        this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
      }
    });
  };

  private getDashboardSectionReports = async () => {
    if (stores.userDocumentStore.selectedDashboardSection) {
      this.setState({ isLoading: true });

      const reports = await getSectionKpiQuestionReportsByDashboard(
        stores.userDocumentStore.selectedDashboardSection.id
      );

      let history = Functions.formatChatHistoryWithSectionKpiQuestionReport(
        stores.userDocumentStore.selectedDashboardSection,
        reports
      );

      this.setState({ reportString: history });
      stores.userDocumentStore.selectedDashboardSection = undefined;
    }
  };

  private stopAvatar = async () => {
    const { avatar } = this.state;
    if (!avatar) return;

    try {
      await avatar.stopAvatar();
    } catch (err) {}

    if (this.state.avatarStream) {
      const tracks = this.state.avatarStream.getTracks();
      tracks.forEach((track) => track.stop());
    }

    this.setState(
      {
        avatar: undefined,
        avatarSessionData: undefined,
        avatarStream: null,
      },
      () => {}
    );
  };

  private getAvatar(tryCount: number = 1): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      const { selectedCompanyAvatar, avatar, isConnecting } = this.state;

      if (this.state.conversationMode !== ConversationMode.Avatar) {
        reject("Conversation mode changed to Chat, stopping getAvatar.");
        return;
      }

      if (avatar) {
        const roomState = avatar.room ? avatar.room.state : null;
        if (roomState === "connected") {
          resolve(true);
          return;
        } else if (roomState === "connecting" || isConnecting) {
          reject(
            "Session is connecting. Please try again after a few seconds."
          );
          return;
        }
      }

      this.setState({
        isAIReady: false,
        avatarError: null,
        isConnecting: true,
      });
      stores.companyAvatarStore.isAvatarLoading = true;

      try {
        const newAvatar = await getAvatar();

        if (this.state.conversationMode !== ConversationMode.Avatar) {
          reject("Conversation mode changed to Chat, stopping getAvatar.");
          return;
        }

        if (!newAvatar) {
          this.setState({
            isAIReady: true,
            avatarError: "Avatar instance not returned.",
            conversationMode: ConversationMode.Chat,
            isConnecting: false,
          });
          stores.companyAvatarStore.isAvatarLoading = false;
          reject("No avatar instance returned.");
          return;
        }

        if (avatar) {
          await this.stopAvatar();
        }

        const avatarName = selectedCompanyAvatar
          ? "305b0cbacae44bafa7105c7805701ec3"
          : "josh_lite3_20230714";
        const voiceId = selectedCompanyAvatar
          ? "0994ba6ed68847f5816259a96a96141a"
          : "5074d5f025b34d059e2e928ef4f5dc95";

        const requestData: IStartAvatarRequest = {
          avatarName,
          newSessionRequest: {
            quality: "high",
            voice: { voiceId },
            iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
          },
        };

        const newSessionData = await newAvatar.createStartAvatar(requestData);

        newAvatar.on(StreamingEvents.STREAM_READY, (event) => {
          if (newAvatar.mediaStream) {
            if (this.mediaStream) {
              this.mediaStream.srcObject = newAvatar.mediaStream;

              this.mediaStream.onloadedmetadata = () => {
                if (this.state.conversationMode !== ConversationMode.Avatar) {
                  reject("Conversation mode changed during avatar load.");
                  return;
                }

                this.mediaStream!.play().catch(() => {
                  this.setState({ avatarError: "Video could not be played." });
                });

                this.setState(
                  {
                    avatar: newAvatar,
                    avatarSessionData: newSessionData,
                    avatarStream: newAvatar.mediaStream,
                    isAIReady: true,
                    isConnecting: false,
                  },
                  () => {
                    stores.companyAvatarStore.isAvatarLoading = false;
                    resolve(true);
                  }
                );
              };
            } else {
              this.setState({
                isAIReady: true,
                avatarError: "Media stream element not found.",
                conversationMode: ConversationMode.Chat,
                isConnecting: false,
              });
              stores.companyAvatarStore.isAvatarLoading = false;
              reject("Media stream element not found.");
            }
          } else {
            this.setState({
              isAIReady: true,
              avatarError: "Avatar media stream could not be obtained.",
              conversationMode: ConversationMode.Chat,
              isConnecting: false,
            });
            stores.companyAvatarStore.isAvatarLoading = false;
            reject("Avatar mediaStream null.");
          }
        });

        newAvatar.on(StreamingEvents.AVATAR_START_TALKING, (event) => {
          this.setState({ isAvatarSpeaking: true });
        });

        newAvatar.on(StreamingEvents.AVATAR_STOP_TALKING, (event) => {
          this.setState({ isAvatarSpeaking: false }, () => {
            this.stopVideoChat();
          });
        });

        newAvatar.on(StreamingEvents.STREAM_DISCONNECTED, (event) => {
          if (this.state.isVideoChatActive) {
            this.stopVideoChat();
          } else {
            this.setState({
              isAIReady: true,
              avatarError: "Stream connection lost.",
              conversationMode: ConversationMode.Chat,
              isConnecting: false,
            });
            stores.companyAvatarStore.isAvatarLoading = false;
          }
        });

        newAvatar.on("error", (error) => {
          if (error.code === 10003) {
            this.setState({
              isAIReady: true,
              avatarError: null,
              conversationMode: ConversationMode.Avatar,
              isConnecting: false,
              avatar: newAvatar,
              avatarSessionData: this.state.avatarSessionData || {},
              avatarStream: newAvatar.mediaStream || this.state.avatarStream,
            });
          } else if (error.code === 10002) {
            this.setState({
              isAIReady: true,
              avatarError:
                "Session is connecting. Please try again after a few seconds.",
              conversationMode: ConversationMode.Chat,
              isConnecting: false,
            });
          } else {
            this.setState({
              isAIReady: true,
              avatarError: `An error occurred with the avatar: ${
                error.message || error
              }`,
              conversationMode: ConversationMode.Chat,
              isConnecting: false,
            });
          }
          stores.companyAvatarStore.isAvatarLoading = false;
        });

        await newAvatar.startSession();
      } catch (error) {
        if (this.state.conversationMode !== ConversationMode.Avatar) {
          reject("Conversation mode changed, stopping avatar attempts.");
          return;
        }

        this.setState({ isConnecting: false });

        if (tryCount < 3) {
          setTimeout(() => {
            this.getAvatar(++tryCount)
              .then(resolve)
              .catch(reject);
          }, 5000);
        } else {
          this.setState({
            isAIReady: true,
            isThinkDeeplyLoading: false,
            avatarError:
              "Avatar session failed after multiple attempts. Please try again later.",
            conversationMode: ConversationMode.Chat,
            isConnecting: false,
          });
          stores.companyAvatarStore.isAvatarLoading = false;
          await this.stopAvatar();
          reject(error);
          toast.error(i18n.ToastMessages.avatarSessionFailed, {
            position: "top-center",
            autoClose: 5000,
          });
        }
      }
    });
  }

  public clear = async () => {
    const { messages } = this.state;

    this.setState({ isLoading: true });

    for (let index = 0; index < messages.length; index++) {
      const message = messages[index];
      await deleteDocumentMessage(message.id);
    }

    this.setState({ isLoading: false, messages: [] });

    this.toggleChatUpdateFlag();
  };

  private stopVoiceAsk = () => {
    this.voiceAskRecognition.stop();

    if (this.voiceAskSilenceTimeoutId) {
      clearTimeout(this.voiceAskSilenceTimeoutId);
      this.voiceAskSilenceTimeoutId = null;
    }

    toast.info(i18n.ToastMessages.voiceAskClosed, {
      position: "top-center",
      autoClose: 3000,
      hideProgressBar: false,
      closeButton: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });

    this.stopLoadingMessages();

    this.setState({
      isVoiceAskActive: false,
      message: this.state.voiceAskTranscript,
    });
  };

  private startVoiceAsk = () => {
    this.voiceAskRecognition.continuous = true;
    this.voiceAskRecognition.interimResults = true;

    let finalTranscript = "";

    try {
      this.voiceAskRecognition.start();
    } catch (error) {
      toast.error(i18n.ToastMessages.voiceAskCouldNotStart, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.setState({
      isVoiceAskActive: true,
      voiceAskTranscript: "",
    });

    const resetVoiceAskSilenceTimer = () => {
      if (this.voiceAskSilenceTimeoutId) {
        clearTimeout(this.voiceAskSilenceTimeoutId);
        this.voiceAskSilenceTimeoutId = null;
      }
      this.voiceAskSilenceTimeoutId = setTimeout(() => {
        this.stopVoiceAsk();
      }, 10000);
    };

    this.voiceAskRecognition.onresult = (event) => {
      let interimTranscript = "";

      resetVoiceAskSilenceTimer();

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript + " ";
        } else {
          interimTranscript += event.results[i][0].transcript + " ";
        }
      }

      const combinedTranscript = finalTranscript + interimTranscript;

      this.setState({
        voiceAskTranscript: combinedTranscript,
        message: combinedTranscript,
      });
    };

    this.voiceAskRecognition.onerror = (event) => {
      if (event.error === "not-allowed") {
        toast.error(i18n.ToastMessages.microphoneAccessDeniedSettings, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
      this.stopVoiceAsk();
    };

    this.voiceAskRecognition.onend = () => {
      if (this.state.isVoiceAskActive) {
        try {
          this.voiceAskRecognition.start();
        } catch (error) {}
      }
    };
  };

  private restartVideoChatRecognition = () => {
    this.videoChatRecognition.stop();

    const SpeechRecognitionClass =
      window.SpeechRecognition || window.webkitSpeechRecognition;
    this.videoChatRecognition = new SpeechRecognitionClass();

    this.videoChatRecognition.continuous = true;
    this.videoChatRecognition.interimResults = true;

    let finalTranscript = "";

    this.videoChatRecognition.onresult = (event) => {
      this.resetSilenceTimer();
      let isFinal = false;

      for (let i = event.resultIndex; i < event.results.length; ++i) {
        if (event.results[i].isFinal) {
          finalTranscript += event.results[i][0].transcript + " ";
          this.setState({ subtitles: finalTranscript });
          isFinal = true;
        } else {
          const interimTranscript = event.results[i][0].transcript;
          this.setState({
            subtitles: finalTranscript + interimTranscript,
          });
        }
      }

      if (isFinal) {
        if (this.videoChatSilenceTimeoutId) {
          clearTimeout(this.videoChatSilenceTimeoutId);
          this.videoChatSilenceTimeoutId = null;
        }

        this.isVideoChatRecognitionActive = false;
        this.videoChatRecognition.stop();

        this.processVideoChatTranscript(finalTranscript.trim());
      }
    };

    this.videoChatRecognition.onerror = (event) => {
      this.stopVideoChat();
    };

    try {
      this.videoChatRecognition.start();
      this.isVideoChatRecognitionActive = true;
    } catch (error) {
      console.error("Error restarting Voice Chat Recognition:", error);
    }

    this.resetSilenceTimer();
  };

  private stopVideoChat = async () => {
    this.videoChatRecognition.stop();

    if (this.videoChatSilenceTimeoutId) {
      clearTimeout(this.videoChatSilenceTimeoutId);
      this.videoChatSilenceTimeoutId = null;
    }

    const { avatar } = this.state;
    if (avatar) {
      try {
        await avatar.interrupt();
        await avatar.closeVoiceChat();
        await stopChat(this.state.userDocument.id);
        this.setState({ isAvatarSpeaking: false });
      } catch (error) {
        console.error("Avatar interrupt hatası:", error);

        toast.error("There was an error when the Avatar speech was cut.", {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }

    this.setState({
      isVideoChatActive: false,
      subtitles: "",
      loadingMessage: "",
    });

    this.stopLoadingMessages();
  };

  private resetSilenceTimer(ignoreSilence: boolean = false) {
    if (this.state.ignoreSilenceCheck || ignoreSilence) {
      if (this.videoChatSilenceTimeoutId) {
        clearTimeout(this.videoChatSilenceTimeoutId);
        this.videoChatSilenceTimeoutId = null;
      }
      return;
    }

    if (this.state.loadingMessage) {
      if (this.videoChatSilenceTimeoutId) {
        clearTimeout(this.videoChatSilenceTimeoutId);
        this.videoChatSilenceTimeoutId = null;
      }
      return;
    }

    if (this.videoChatSilenceTimeoutId) {
      clearTimeout(this.videoChatSilenceTimeoutId);
    }

    this.videoChatSilenceTimeoutId = setTimeout(() => {
      this.stopVideoChat();
    }, 180000);
  }

  private handleVoiceChatRecord = () => {
    analytics.trackEvent("voice_chat_button_pressed", {
      category: "chat_events",
      label: "voice_chat_toggle",
    });
    if (!stores.userStore.currentUser.subscription) {
      toast.error(i18n.ToastMessages.voiceAskError, {
        position: "top-center",
        autoClose: 5000,
        theme: "light",
      });
      return;
    }

    const isTheChiefSelected =
      this.props.canUseTheChief && !this.state.selectedCompanyModel;

    if (!this.state.selectedCompanyAvatar && !isTheChiefSelected) {
      toast.error(
        "Please select an Avatar or 'The Chief' model before using Voice Chat.",
        { position: "top-center", autoClose: 5000, theme: "light" }
      );
      return;
    }

    if (this.state.isVoiceChatActive) {
      return;
    }

    this.setState(
      {
        isVoiceChatActive: true,
        showVoiceChatModal: true,
      },
      () => {
        window.dispatchEvent(
          new CustomEvent("voicechatModeChange", {
            detail: { isVoiceChatActive: true },
          })
        );
      }
    );
  };

  private handleVideoChatRecord = async () => {
    analytics.trackEvent("video_chat_button_pressed", {
      category: "chat_events",
      label: "video_chat_toggle",
    });
    try {
      if (!this.state.canUseLiveAvatar) {
        toast.error(i18n.ToastMessages.liveAvatarFeatureRequired, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      if (
        this.state.canUseLiveAvatar &&
        this.state.conversationMode === ConversationMode.Chat
      ) {
        toast.warn(i18n.ToastMessages.switchToLiveAvatarMode, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        return;
      }

      if (!this.state.avatar) {
        await this.getAvatar();
      }

      if (!this.state.isVideoChatActive) {
        this.startLoadingMessages();
        this.restartVideoChatRecognition();
        this.setState({ isVideoChatActive: true });
      } else {
        this.stopLoadingMessages();
        this.stopVideoChat();
      }

      if (!this.state.isVideoChatActive) {
        this.setState({ isAIReady: false });

        try {
          if (!this.state.avatar) {
            await this.getAvatar();
          }

          this.setState({
            isVideoChatActive: true,
            isAIReady: true,
            subtitles: "",
            loadingMessage: "",
          });

          toast.success(i18n.ToastMessages.videoChatStarted, {
            position: "top-center",
            autoClose: 3000,
            hideProgressBar: false,
            closeButton: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });

          this.videoChatRecognition.continuous = true;
          this.videoChatRecognition.interimResults = true;

          let finalTranscript = "";

          this.resetSilenceTimer();

          this.videoChatRecognition.onresult = (event) => {
            this.resetSilenceTimer();
            let isFinal = false;

            for (let i = event.resultIndex; i < event.results.length; ++i) {
              if (event.results[i].isFinal) {
                finalTranscript += event.results[i][0].transcript + " ";
                this.setState({ subtitles: finalTranscript });
                isFinal = true;
              } else {
                const interimTranscript = event.results[i][0].transcript;
                this.setState({
                  subtitles: finalTranscript + interimTranscript,
                });
              }
            }

            if (isFinal) {
              if (this.videoChatSilenceTimeoutId) {
                clearTimeout(this.videoChatSilenceTimeoutId);
                this.videoChatSilenceTimeoutId = null;
              }

              this.isVideoChatRecognitionActive = false;
              this.videoChatRecognition.stop();

              this.processVideoChatTranscript(finalTranscript.trim());
            }
          };

          this.videoChatRecognition.onerror = (event) => {
            this.stopVideoChat();
          };

          this.videoChatRecognition.onend = () => {};

          if (!this.isVideoChatRecognitionActive) {
            try {
              this.videoChatRecognition.start();
              this.isVideoChatRecognitionActive = true;
            } catch (error) {}
          }
        } catch (error) {
          this.setState({ isAIReady: true });
          toast.error(i18n.ToastMessages.avatarCouldNotStart, {
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeButton: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      } else {
        this.stopVideoChat();
      }
    } catch (error) {
      this.setState({ isAIReady: true });
      if (
        this.state.canUseLiveAvatar &&
        this.state.conversationMode !== ConversationMode.Avatar
      ) {
        toast.error(i18n.ToastMessages.switchToLiveAvatarBeforeVideoChat, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        toast.error(i18n.ToastMessages.unexpectedError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    }
  };

  private processVideoChatTranscript = async (transcript: string) => {
    const {
      reportString,
      messages,
      userDocument,
      selectedCompanyAvatar,
      canUseTheChief,
      isWebSearchActive,
    } = this.state;

    this.setState({
      loadingMessage: "Awaiting response...",
      ignoreSilenceCheck: true,
    });

    let history: string | undefined = undefined;
    if (reportString) {
      history = reportString + Functions.formatChatHistory(messages);
    }

    try {
      const companyId = stores.companyStore.selectedUserCompany?.id;
      if (!companyId) {
        toast.error(i18n.ToastMessages.selectedCompanyNotDefined, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
        this.setState({ loadingMessage: "" });
        return;
      }

      const newUserMessage = await createDocumentMessage(
        userDocument.id,
        undefined,
        undefined,
        undefined,
        Date.now(),
        encodeURIComponent(transcript),
        ""
      );

      let chatResult;

      if (selectedCompanyAvatar) {
        chatResult = await chatAvatar(
          userDocument.id,
          newUserMessage.id,
          selectedCompanyAvatar.company_id,
          selectedCompanyAvatar.id,
          encodeURIComponent(transcript),
          isWebSearchActive,
          history ? history : undefined
        );
      } else if (canUseTheChief) {
        chatResult = await chatGeneral(
          userDocument.id,
          newUserMessage.id,
          companyId,
          encodeURIComponent(transcript),
          1000,
          this.state.isWebSearchActive,
          history ? history : undefined
        );
      } else {
        chatResult = await chatGeneral(
          userDocument.id,
          newUserMessage.id,
          companyId,
          encodeURIComponent(transcript),
          1000,
          isWebSearchActive,
          history ? history : undefined
        );
      }

      if (chatResult) {
        const updatedMessage: DocumentMessage = {
          ...newUserMessage,
          message_reply: encodeURIComponent(chatResult.message),
          source_documents: chatResult.sourceDocs
            ? encodeURIComponent(chatResult.sourceDocs.join(","))
            : newUserMessage.source_documents,
        };

        await updateDocumentMessage(updatedMessage);

        this.setState(
          (prevState) => ({
            messages: prevState.messages.map((msg) =>
              msg.id === newUserMessage.id ? updatedMessage : msg
            ),
            loadingMessage: "",
            ignoreSilenceCheck: false,
          }),
          () => {}
        );

        if (this.videoChatSilenceTimeoutId) {
          clearTimeout(this.videoChatSilenceTimeoutId);
          this.videoChatSilenceTimeoutId = null;
        }
        this.resetSilenceTimer();
      }
    } catch (error) {
      this.setState({
        loadingMessage: "",
        ignoreSilenceCheck: false,
      });
      toast.error(i18n.ToastMessages.chatProcessError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  private getLastMessage = async () => {
    const { userDocument } = this.state;

    const messages = await getDocumentMessages(userDocument.id);

    if (messages.length === 0) {
      return null;
    }

    const lastMessage = messages[messages.length - 1];

    if (
      lastMessage.message_reply === null ||
      lastMessage.message_reply.trim() === ""
    ) {
      return lastMessage;
    }

    return null;
  };

  private handleThinkDeeperClick = async (
    documentMessage: DocumentMessage,
    k: number
  ) => {
    const { isAIReady, reportString, messages } = this.state;
    if (!isAIReady) {
      return;
    }

    let history: string | undefined = undefined;
    if (reportString) {
      history = reportString + Functions.formatChatHistory(messages);
    }

    this.setState({ isAIReady: false, isThinkDeeplyLoading: true });
    stores.companyAvatarStore.isAvatarLoading = true;

    const emoji = "🧠";

    const decodedMessage = Functions.decodeMessage(documentMessage.message);
    const modifiedMessage = decodedMessage.startsWith(emoji)
      ? decodedMessage
      : `${emoji} ${decodedMessage}`;
    const encodedMessage = encodeURIComponent(
      Functions.sanitizeAndStoreMentions(modifiedMessage)
    );

    const newUserMessage = await createDocumentMessage(
      this.state.userDocument.id,
      undefined,
      undefined,
      undefined,
      Date.now(),
      encodedMessage,
      ""
    );

    this.setState(
      (prevState) => ({
        messages: [...prevState.messages, newUserMessage],
      }),
      () => {
        const shouldScroll = this.isScrolledToBottom();
        if (shouldScroll && this.messagesEndRef) {
          this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
        }
      }
    );

    if (stores.companyStore.selectedUserCompany) {
      const chatResult = await chatGeneral(
        this.state.userDocument.id,
        newUserMessage.id,
        stores.companyStore.selectedUserCompany.id,
        encodedMessage,
        k,
        this.state.isWebSearchActive,
        history ? history : undefined
      );

      if (chatResult) {
        const messageIndex = this.state.messages.findIndex(
          (m) => m.id === newUserMessage.id
        );
        if (messageIndex !== -1) {
          const updatedMessage: DocumentMessage = {
            ...this.state.messages[messageIndex],
            message_reply: encodeURIComponent(chatResult.message),
            source_documents: chatResult.sourceDocs
              ? encodeURIComponent(chatResult.sourceDocs.join(","))
              : this.state.messages[messageIndex].source_documents,
          };

          const updatedMessages = [...this.state.messages];
          updatedMessages[messageIndex] = updatedMessage;

          await updateDocumentMessage(updatedMessage);

          this.setState({ messages: updatedMessages }, () => {
            const shouldScroll = this.isScrolledToBottom();
            if (shouldScroll && this.messagesEndRef) {
              this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
            }
          });
        }
      }
    }

    this.setState({ isAIReady: true, isThinkDeeplyLoading: false });
    stores.companyAvatarStore.isAvatarLoading = false;
  };

  private sendMessage = async () => {
    const {
      message,
      selectedCompanyAppIds,
      selectedCompanyModel,
      selectedCompanyAvatar,
      startNewChat,
      reportString,
      messages,
    } = this.state;

    const MAX_TOKENS = 8192;
    const AVERAGE_CHARS_PER_TOKEN = 4;

    const encodedMessage = encodeURIComponent(
      Functions.sanitizeAndStoreMentions(message)
    );

    const estimatedTokenCount = Math.ceil(
      encodedMessage.length / AVERAGE_CHARS_PER_TOKEN
    );

    if (estimatedTokenCount > MAX_TOKENS) {
      toast.error(i18n.ToastMessages.inputExceedsTokenLimit, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.setState({ isAIReady: false });
    stores.companyAvatarStore.isAvatarLoading = true;

    let history: string | undefined = undefined;
    if (reportString) {
      history = reportString + Functions.formatChatHistory(messages);
    }

    if (startNewChat) {
      await stopChat(this.state.userDocument.id);

      let messageTextForName = Functions.sanitizeAndStoreMentions(message);

      if (messageTextForName.length > 50) {
        messageTextForName = messageTextForName.substring(0, 50) + "...";
      }

      const newUserDocument = await stores.userDocumentStore.createUserDocument(
        "New Chat"
      );

      await new Promise<void>((resolve) => {
        this.setState(
          {
            userDocument: newUserDocument,
            startNewChat: false,
            messages: [],
          },
          () => {
            resolve();
          }
        );
      });

      stores.userDocumentStore.selectedUserDocument = newUserDocument;
      stores.userDocumentStore.lastSelectedDocumentId = newUserDocument.id;
    }

    analytics.trackEvent("send_message_pressed", {
      category: "chat_events",
      label: "send_message_button_pressed",
    });

    setTimeout(async () => {
      const lastMessage = await this.getLastMessage();
      if (lastMessage) {
        await deleteDocumentMessage(lastMessage.id);

        this.setState(
          (prevState) => ({
            messages: prevState.messages.filter(
              (msg) => msg.id !== lastMessage.id
            ),
          }),
          () => {}
        );
      }
    }, 200);

    if (selectedCompanyModel && selectedCompanyAppIds.length === 0) {
      toast.warn(i18n.ToastMessages.selectedAppIssue, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });

      this.setState({ isAIReady: true });
      stores.companyAvatarStore.isAvatarLoading = false;
      return;
    }

    if (!encodedMessage) {
      return;
    }

    if (
      (!stores.companyStore.selectedUserCompany ||
        stores.companyStore.selectedUserCompany.index_status ===
          CompanyIndexStatus.Failed) &&
      !selectedCompanyModel &&
      !selectedCompanyAvatar
    ) {
      return;
    }

    this.setState({ isAIReady: false });
    stores.companyAvatarStore.isAvatarLoading = true;

    if (this.state.messages.length === 0) {
      const sanitizedMessage = Functions.sanitizeAndStoreMentions(message);
      const trimmedFileName = Functions.getTrimmedFileName(sanitizedMessage);
      const updatedUserDocument = { ...this.state.userDocument };
      updatedUserDocument.name = encodeURIComponent(trimmedFileName);
      await stores.userDocumentStore.updateUserDocument(updatedUserDocument);
      this.setState({ userDocument: updatedUserDocument });
    }

    const createNewUserMessage = async (
      appId: number | undefined,
      avatarId: number | undefined,
      modelId: number | undefined
    ) => {
      const newUserMessage = await createDocumentMessage(
        this.state.userDocument.id,
        appId,
        avatarId,
        modelId,
        Date.now(),
        encodedMessage,
        ""
      );

      this.setState(
        (prevState) => ({
          messages: [...prevState.messages, newUserMessage],
          message: "",
          appId: undefined,
          avatarId: undefined,
          modelId: undefined,
        }),
        () => {
          const shouldScroll = this.isScrolledToBottom();
          if (shouldScroll && this.messagesEndRef) {
            this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
          }
        }
      );
      scrollToBottom();

      return newUserMessage;
    };

    const handleChatResult = async (
      chatResult: ChatResult,
      newUserMessage: DocumentMessage
    ) => {
      if (chatResult) {
        const messageIndex = this.state.messages.findIndex(
          (m) => m.id === newUserMessage.id
        );
        if (messageIndex !== -1) {
          this.state.messages[messageIndex].message_reply = encodeURIComponent(
            chatResult.message
          );
          if (chatResult.sourceDocs) {
            this.state.messages[messageIndex].source_documents =
              encodeURIComponent(chatResult.sourceDocs.join(","));
          }
          await updateDocumentMessage(this.state.messages[messageIndex]);

          this.setState({ messages: this.state.messages }, () => {
            const shouldScroll = this.isScrolledToBottom();
            if (shouldScroll && this.messagesEndRef) {
              this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
            }
          });
          scrollToBottom();
        }
      }
    };

    const scrollToBottom = () => {
      if (this.messagesEndRef) {
        this.messagesEndRef.scrollIntoView({ behavior: "smooth" });
      }
    };

    const thechiefFeature = await stores.userStore.checkSubscribedFeatureType(
      FeatureType.TheChief
    );

    if (
      thechiefFeature &&
      stores.companyStore.selectedUserCompany &&
      stores.companyStore.selectedUserCompany.index_status ===
        CompanyIndexStatus.Connected &&
      !selectedCompanyAvatar &&
      !selectedCompanyModel
    ) {
      const newUserMessage = await createNewUserMessage(
        undefined,
        undefined,
        undefined
      );

      const chatResult = await chatGeneral(
        this.state.userDocument.id,
        newUserMessage.id,
        stores.companyStore.selectedUserCompany.id,
        encodedMessage,
        1000,
        this.state.isWebSearchActive,
        history ? history : undefined
      );

      await handleChatResult(chatResult, newUserMessage);
    } else if (selectedCompanyModel && !selectedCompanyAvatar) {
      for (let appId of selectedCompanyAppIds) {
        const companyApp = stores.companyAppStore.companyApps.find(
          (item) => item.id.toString() === appId
        );

        if (companyApp) {
          const newUserMessage = await createNewUserMessage(
            companyApp.id,
            undefined,
            selectedCompanyModel.id
          );

          const chatResult = await chat(
            this.state.userDocument.id,
            newUserMessage.id,
            selectedCompanyModel,
            companyApp.company_id,
            companyApp.app_id,
            encodedMessage,
            this.state.isWebSearchActive
          );

          await handleChatResult(chatResult, newUserMessage);
        }
      }
    } else if (!selectedCompanyModel && selectedCompanyAvatar) {
      const newUserMessage = await createNewUserMessage(
        undefined,
        selectedCompanyAvatar.id,
        undefined
      );

      const chatResult = await chatAvatar(
        this.state.userDocument.id,
        newUserMessage.id,
        selectedCompanyAvatar.company_id,
        selectedCompanyAvatar.id,
        encodedMessage,
        this.state.isWebSearchActive,
        history ? history : undefined
      );

      await handleChatResult(chatResult, newUserMessage);
    }

    this.setState({ isAIReady: true });
  };

  private toggleChatUpdateFlag = () => {
    this.setState(
      (prevState) => ({
        chatUpdateFlag: !prevState.chatUpdateFlag,
      }),
      () => {}
    );
  };

  private disconnectMediaStream = () => {
    if (this.state.avatarStream) {
      const tracks = this.state.avatarStream.getTracks();
      tracks.forEach((track) => track.stop());
    }

    this.setState(
      {
        avatar: undefined,
        avatarSessionData: undefined,
        avatarStream: null,
      },
      () => {}
    );
  };

  private onEnterPress = (e: any) => {
    const { isAIReady, canUseTheChief } = this.state;
    const isCurrentUserAdmin = stores.userStore.isCurrentUserAdmin;
    const hasSelectedAvatar = !!this.state.selectedCompanyAvatar;
    const isFreeUser = stores.userStore.isFreeUser;
    const hasSelectedModel = !!this.state.selectedCompanyModel;
    const hasMentionedApp = this.state.selectedCompanyAppIds.length > 0;

    if (
      e.keyCode === 13 &&
      e.shiftKey === false &&
      isAIReady &&
      (isCurrentUserAdmin || hasSelectedAvatar || canUseTheChief) &&
      (!isFreeUser || (hasSelectedModel && hasMentionedApp))
    ) {
      e.preventDefault();
      this.sendMessage();
    }
  };

  private handleShareClick = () => {
    const { messages, userDocument } = this.state;

    analytics.trackEvent("share_chat_clicked", {
      category: "chat_events",
      label: "share_button_pressed",
    });

    ExportHelper.exportDocxFile(messages, userDocument);
  };

  private handleMessageChange = (e: any) => {
    const newMessage = e.target.value;
    this.setState({ message: newMessage });

    if (
      this.state.isCopied &&
      newMessage.trim() !== this.state.originalCopiedMessage.trim()
    ) {
      this.setState({ startNewChat: true });
    }

    const value = newMessage;
    const idPattern = /@\[(?:@([A-Za-z0-9-_]+)|(.*?))\]\((\d+)\)/g;
    let match;
    const matches: string[] = [];

    while ((match = idPattern.exec(value)) !== null) {
      const id = match[3];
      matches.push(id);
    }

    this.setState({ selectedCompanyAppIds: matches });
  };

  private handleReplyLike = async (messageId: number, liked: boolean) => {
    const updatedMessages = this.state.messages.map((message) => {
      if (message.id === messageId) {
        return { ...message, is_liked: true };
      }
      return message;
    });

    this.setState({ messages: updatedMessages });

    const messageToUpdate = this.state.messages.find((m) => m.id === messageId);
    if (messageToUpdate) {
      await updateDocumentMessage({ ...messageToUpdate, is_liked: true });
    }
  };

  private handleReplyDisLike = async (messageId: number, liked: boolean) => {
    const updatedMessages = this.state.messages.map((message) => {
      if (message.id === messageId) {
        return { ...message, is_liked: false };
      }
      return message;
    });

    this.setState({ messages: updatedMessages });

    const messageToUpdate = this.state.messages.find((m) => m.id === messageId);
    if (messageToUpdate) {
      await updateDocumentMessage({ ...messageToUpdate, is_liked: false });
    }
  };

  private handleRewriteMessage = (documentMessage: DocumentMessage) => {
    const decodedMessage = Functions.decodeMessage(documentMessage.message);

    const idPattern = /@\[(.*?)]\((\d+)\)/g;
    const matches: MentionItem[] = [];
    let match;

    while ((match = idPattern.exec(decodedMessage)) !== null) {
      const display = match[1];
      const id = match[2];
      matches.push({ id, display });
    }

    let cleanedMessage = decodedMessage.replace(idPattern, "").trim();
    cleanedMessage = cleanedMessage.replace(/@[a-zA-Z0-9_]+/g, "").trim();

    const app = stores.companyAppStore.companyApps.find(
      (appItem) => appItem.app_id === documentMessage.company_app_id
    );

    const appMention = app
      ? {
          id: app.id.toString(),
          display: `@${app.app.name.replace(/\s+/g, "")}`,
        }
      : null;

    const mentions: MentionItem[] = [];
    if (appMention) mentions.push(appMention);

    const initialMessage = `${mentions
      .map((m) => `@[${m.display}](${m.id})`)
      .join(" ")} ${cleanedMessage}`.trim();

    this.setState(
      {
        message: initialMessage,
        selectedCompanyAppIds: mentions.map((m) => m.id),
        mentions,
        appId: documentMessage.company_app_id || undefined,
        avatarId: documentMessage.company_avatar_id || undefined,
        modelId: documentMessage.company_model_id || undefined,
        startNewChat: true,
        isCopied: false,
        originalCopiedMessage: "",
      },
      () => {}
    );
  };

  private handleCopyToInput = (documentMessage: DocumentMessage) => {
    const decodedMessage = Functions.decodeMessage(documentMessage.message);

    const idPattern = /@\[(.*?)]\((\d+)\)/g;
    const matches: MentionItem[] = [];
    let match;

    while ((match = idPattern.exec(decodedMessage)) !== null) {
      const display = match[1];
      const id = match[2];
      matches.push({ id, display });
    }

    let cleanedMessage = decodedMessage.replace(idPattern, "").trim();
    cleanedMessage = cleanedMessage.replace(/@[a-zA-Z0-9_]+/g, "").trim();

    const app = stores.companyAppStore.companyApps.find(
      (appItem) => appItem.app_id === documentMessage.company_app_id
    );

    const appMention = app
      ? {
          id: app.id.toString(),
          display: `@${app.app.name.replace(/\s+/g, "")}`,
        }
      : null;

    const mentions: MentionItem[] = [];
    if (appMention) mentions.push(appMention);

    const initialMessage = `${mentions
      .map((m) => `@[${m.display}](${m.id})`)
      .join(" ")} ${cleanedMessage}`.trim();

    this.setState(
      {
        message: initialMessage,
        selectedCompanyAppIds: mentions.map((m) => m.id),
        mentions,
        appId: documentMessage.company_app_id || undefined,
        avatarId: documentMessage.company_avatar_id || undefined,
        modelId: documentMessage.company_model_id || undefined,
        startNewChat: false,
        originalCopiedMessage: initialMessage,
        isCopied: true,
      },
      () => {}
    );
  };

  private handleStopChat = async () => {
    const { userDocument } = this.state;
    analytics.trackEvent("stop_chat_pressed", {
      category: "chat_events",
      label: "stop_chat_button_pressed",
    });

    try {
      const result = await stopChat(userDocument.id);

      if (result && result.ok) {
        this.setState({ isAIReady: true });
      }
    } catch (error) {
      toast.error(i18n.ToastMessages.stopChatFailed, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  private renderSuggestionItem = (
    suggestion: SuggestionDataItem,
    search: string,
    highlightedDisplay: React.ReactNode,
    index: number,
    focused: boolean
  ) => {
    const companyApp = stores.companyAppStore.companyApps.find(
      (companyApp) => companyApp.id === suggestion.id
    );

    if (companyApp) {
      return (
        <div
          className={`d-flex align-items-center p-2 ${
            focused ? "bg-light-primary" : "bg-body"
          }`}
        >
          <div className="me-3 position-relative">
            <img
              src={toAbsoluteUrl(companyApp.app.logo)}
              className="align-self-center"
              style={{ width: 20 }}
              alt=""
            />
          </div>

          <div className="d-flex flex-column justify-content-center">
            <a className="fs-6 text-gray-800 fw-bolder text-hover-primary">
              {suggestion.display}
            </a>
          </div>
        </div>
      );
    }
    return null;
  };

  private startLoadingMessages = () => {
    this.loadingIntervalId = setInterval(() => {
      this.setState((prevState) => ({
        currentLoadingMessageIndex:
          (prevState.currentLoadingMessageIndex + 1) %
          prevState.loadingMessages.length,
      }));
    }, 3000);
  };

  private stopLoadingMessages = () => {
    if (this.loadingIntervalId) {
      clearInterval(this.loadingIntervalId);
      this.loadingIntervalId = null;
    }
    this.setState({ currentLoadingMessageIndex: 0 });
  };

  private isScrolledToBottom = (): boolean => {
    if (this.messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.messagesContainerRef.current;
      return scrollHeight - scrollTop - clientHeight < 50;
    }
    return false;
  };
  private scrollToBottom = () => {
    if (this.messagesContainerRef.current) {
      this.messagesContainerRef.current.scrollTo({
        top: this.messagesContainerRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  };

  private handleScroll = () => {
    if (this.messagesContainerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } =
        this.messagesContainerRef.current;
      this.isUserScrolling = scrollHeight - scrollTop - clientHeight > 50;
    }
  };

  render() {
    const {
      messages,
      message,
      isLoading,
      isAIReady,
      chatUpdateFlag,
      selectedCompanyModel,
      selectedCompanyAvatar,
      conversationMode,
      filteredCompanyApps,
      canUseTheChief,
      isElectron,
      refreshTime,
      avatarError,
      subtitles,
      loadingMessage,
      loadingMessages,
      currentLoadingMessageIndex,
    } = this.state;
    const {
      canUseLiveAvatar,
      mentionInputId,
      downloadButtonId,
      webSearchButtonId,
    } = this.props;

    const maxInputLength = 8192 * 3;

    const messagePlaceholderText = !stores.companyStorageStore
      .selectedCompanyStorage
      ? "Please connect your storage.."
      : stores.companyAppStore.companyApps.length === 0
      ? "Please connect at least one app.."
      : stores.companyAvatarStore.isAvatarLoading
      ? "Avatar is loading, please wait a moment.."
      : stores.userStore.isFreeUser
      ? selectedCompanyAvatar
        ? `Ask the ${selectedCompanyAvatar.name} avatar..`
        : "Ask your question.."
      : stores.companyStore.selectedUserCompany &&
        stores.companyStore.selectedUserCompany.index_status ===
          CompanyIndexStatus.Connected &&
        !selectedCompanyModel &&
        !selectedCompanyAvatar
      ? canUseTheChief
        ? "Ask The Chief.."
        : "Choose a model or avatar and ask a question example: @slack [your question].."
      : !selectedCompanyModel && selectedCompanyAvatar
      ? `Ask the ${selectedCompanyAvatar.name}..`
      : selectedCompanyModel
      ? "Ask: @slack [your question].."
      : "Choose a model or avatar and ask a question example: @slack [your question]..";

    return (
      <div
        className="card-body d-flex flex-column"
        id={"kt_chat_messenger_body"}
        style={{
          height: "calc(100vh - 185px)",
          padding: "1rem",
        }}
      >
        <div
          className="d-flex bg-body align-items-center"
          style={{ width: 70 }}
        >
          <div
            className={`${
              conversationMode === ConversationMode.Chat
                ? "bg-light-primary"
                : "bg-light"
            } p-4`}
            onClick={() =>
              this.handleConversationModeChange(ConversationMode.Chat)
            }
            style={{
              borderTopLeftRadius: 5,
              borderBottomLeftRadius: 5,
              cursor: "pointer",
            }}
          >
            <KTSVG
              path="media/icons/duotune/communication/com007.svg"
              className="svg-icon-1 me-2"
            />
          </div>

          <div
            className={`${
              conversationMode === ConversationMode.Avatar
                ? "bg-light-primary"
                : "bg-light"
            } p-4`}
            onClick={() =>
              this.handleConversationModeChange(ConversationMode.Avatar)
            }
            style={{
              borderTopRightRadius: 5,
              borderBottomRightRadius: 5,
              cursor: "pointer",
            }}
          >
            <KTSVG
              path="media/icons/duotune/technology/teh002.svg"
              className="svg-icon-1 me-2"
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            flex: 1,
            marginTop: 10,
            borderRadius: 10,
            textAlign: "center",
            display:
              conversationMode === ConversationMode.Avatar && !avatarError
                ? "block"
                : "none",
          }}
        >
          {canUseLiveAvatar ? (
            <>
              <video
                playsInline
                autoPlay
                style={{ height: "80%", maxWidth: "80%", borderRadius: 10 }}
                ref={(ref) => (this.mediaStream = ref)}
                muted={conversationMode !== ConversationMode.Avatar}
                onPlay={() =>
                  (stores.companyAvatarStore.isAvatarLoading = false)
                }
                onLoadedMetadata={() =>
                  (stores.companyAvatarStore.isAvatarLoading = false)
                }
              ></video>

              {stores.companyAvatarStore.isAvatarLoading && !avatarError ? (
                <span
                  className="spinner-border text-secondary ms-1"
                  style={{ position: "absolute", left: "50%", top: "44%" }}
                  role="status"
                ></span>
              ) : null}
            </>
          ) : null}

          {avatarError && (
            <div className="alert alert-danger mt-2" role="alert">
              {avatarError}
            </div>
          )}
        </div>

        <div
          style={{
            width: "100%",
            marginTop: 10,
            padding: "0.5rem",
            backgroundColor: "#f8f9fa",
            borderRadius: "5px",
            minHeight: "50px",
            display: this.state.isVideoChatActive ? "block" : "none",
          }}
        >
          {loadingMessage && (
            <div className="loading-message" style={{ color: "#6c757d" }}>
              {loadingMessages[currentLoadingMessageIndex]}
            </div>
          )}
          {subtitles && (
            <div className="subtitles" style={{ color: "#343a40" }}>
              {subtitles}
            </div>
          )}
        </div>

        {conversationMode === ConversationMode.Chat ? (
          <div
            className={clsx("scroll-y me-n5 pe-5")}
            style={{
              flex: 1,
              overflowY: "auto",
              marginTop: 15,
            }}
            id="messages"
            ref={this.messagesContainerRef}
            data-kt-element="messages"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-dependencies="#kt_header, #kt_app_header, #kt_app_toolbar, #kt_toolbar, #kt_footer, #kt_app_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer"
            data-kt-scroll-wrappers="#kt_content, #kt_app_content, #kt_chat_messenger_body"
          >
            {messages.length > 0 && !isLoading ? (
              messages.map((message, index) => (
                <MessageItem
                  key={message.id}
                  isAIReady={isAIReady}
                  chatUpdateFlag={chatUpdateFlag}
                  documentMessage={message}
                  index={index}
                  canUseLiveAvatar={canUseLiveAvatar}
                  onReplyLike={() => this.handleReplyLike(message.id, true)}
                  onReplyDislike={() =>
                    this.handleReplyDisLike(message.id, false)
                  }
                  onRewriteMessage={this.handleRewriteMessage}
                  onThinkDeeperClick={this.handleThinkDeeperClick}
                  onQuestionItemPress={(questionItem) =>
                    this.setState({ message: questionItem }, () => {
                      this.sendMessage();
                    })
                  }
                  onSpeechToAvatar={this.handleSpeechToAvatar}
                  refreshTime={refreshTime}
                  isThinkDeeplyLoading={this.state.isThinkDeeplyLoading}
                  onCopyToInput={this.handleCopyToInput}
                />
              ))
            ) : (
              <div className="text-gray-600 d-flex text-center w-100 align-content-center justify-content-center mt-6 mb-14">
                {isLoading ? (
                  <span
                    className="spinner-border text-secondary ms-1 position-relative"
                    role="status"
                  ></span>
                ) : (
                  "No message records found."
                )}
              </div>
            )}

            <div ref={(ref) => (this.messagesEndRef = ref)} />
          </div>
        ) : null}

        <div
          className="card-footer pt-4 shadow-sm"
          id={"kt_chat_messenger_footer"}
          style={{
            border: "1px solid gray",
            borderRadius: 15,
            marginTop: 10,
            flexShrink: 0,
            opacity: isLoading ? 0.5 : 1,
            pointerEvents: isLoading ? "none" : "all",
            position: "relative",
            bottom: 0,
            maxHeight: "300px",
            overflowY: "hidden",
          }}
        >
          <button
            id="voicechatButtonId"
            onClick={this.handleVoiceChatRecord}
            className={`btn btn-sm btn-icon ${
              this.state.isVoiceChatActive
                ? "btn-danger"
                : "btn-active-light-primary"
            } me-1`}
            data-bs-toggle="tooltip"
            title="Voice Chat"
            style={{
              position: "absolute",
              right: "58px",
              zIndex: "0",
            }}
          >
            {this.state.isVoiceChatActive ? (
              <i className="bi bi-stop-circle fs-2 p-0"></i>
            ) : (
              <i className="bi bi-chat-left-quote fs-2 p-0"></i>
            )}
          </button>

          <button
            id="videochatButtonId"
            onClick={this.handleVideoChatRecord}
            className={`btn btn-sm btn-icon ${
              this.state.isVideoChatActive
                ? "btn-danger"
                : "btn-active-light-primary"
            } me-1`}
            data-bs-toggle="tooltip"
            title="Video Chat"
            disabled={this.state.isVoiceAskActive}
            style={{ position: "absolute", right: "21px", zIndex: "0" }}
          >
            {this.state.isVideoChatActive ? (
              <i className="bi bi-stop-circle fs-2 p-0"></i>
            ) : (
              <i className="bi bi-camera fs-2 p-0"></i>
            )}
          </button>

          <div
            className="d-flex flex-column flex-grow-1 overflow-hidden"
            style={{
              height: "100%",
              minHeight: "50px",
              maxHeight: "150px",
            }}
          >
            <div
              className="overflow-auto"
              style={{
                flexGrow: 1,
              }}
            >
              <MentionsInput
                id={mentionInputId}
                rows={1}
                className="form-control form-control-flush mb-3"
                placeholder={messagePlaceholderText}
                value={message}
                onKeyDown={this.onEnterPress}
                onChange={this.handleMessageChange}
                maxLength={maxInputLength}
                style={{
                  width: "81%",
                }}
              >
                <Mention
                  trigger="@"
                  data={
                    stores.userStore.isCurrentUserAdmin
                      ? stores.companyAppStore.companyApps
                          .filter(
                            (companyApp) =>
                              companyApp.status === CompanyAppStatus.Connected
                          )
                          .map((companyApp) => ({
                            id: companyApp.id,
                            display: `@${companyApp.app.name.replace(
                              /\s+/g,
                              ""
                            )}`,
                          }))
                      : filteredCompanyApps
                          .filter(
                            (companyApp) =>
                              companyApp.status === CompanyAppStatus.Connected
                          )
                          .map((companyApp) => ({
                            id: companyApp.id,
                            display: `@${companyApp.app.name.replace(
                              /\s+/g,
                              ""
                            )}`,
                          }))
                  }
                  renderSuggestion={this.renderSuggestionItem}
                  appendSpaceOnAdd={true}
                />
              </MentionsInput>
            </div>
          </div>

          <div
            className="d-flex flex-stack"
            style={{
              position: "sticky",
              bottom: 0,
            }}
          >
            <div className="d-flex align-items-center">
              <button
                id={downloadButtonId}
                onClick={this.handleShareClick}
                className="btn btn-sm btn-icon btn-active-light-primary"
                type="button"
                disabled={this.state.isVideoChatActive}
                data-bs-toggle="tooltip"
                title="Share chat"
              >
                <i className="bi bi-download fs-2 p-0"></i>
              </button>

              <button
                id={webSearchButtonId}
                className={`btn btn-sm btn-icon  ${
                  this.state.isWebSearchActive
                    ? " btn-active-light-primary bg-light-primary"
                    : "btn-active-light-primary"
                }`}
                aria-pressed="false"
                aria-label="Web Search"
                disabled={this.state.isVideoChatActive}
                data-bs-toggle="tooltip"
                title="Search"
                onClick={async () => {
                  if (this.state.isVideoChatActive) return;

                  const canUseWebSearch =
                    await stores.userStore.checkSubscribedFeatureType(
                      FeatureType.WebSearch
                    );
                  if (!canUseWebSearch) {
                    toast.error(i18n.ToastMessages.webSearchError, {
                      position: "top-center",
                      autoClose: 5000,
                      hideProgressBar: false,
                      closeButton: true,
                      pauseOnHover: true,
                      draggable: true,
                      progress: undefined,
                      theme: "light",
                    });
                  } else {
                    this.setState((prevState) => {
                      const newState = !prevState.isWebSearchActive;
                      localStorage.setItem(
                        "isWebSearchActive",
                        newState.toString()
                      );
                      analytics.trackEvent("web_search_toggle_pressed", {
                        category: "chat_events",
                        label: newState
                          ? "web_search_enabled"
                          : "web_search_disabled",
                      });
                      return { isWebSearchActive: newState };
                    });
                  }
                }}
                style={{
                  width: this.state.isWebSearchActive ? "100px" : "40px",
                  transition: "width 0.3s ease-in-out",
                  border: "none",
                  overflow: "hidden",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {this.state.isWebSearchActive ? (
                  <>
                    <i className="bi bi-globe fs-2 text-primary p-0"></i>
                    <span
                      className="text-primary ms-2"
                      style={{
                        marginLeft: "5px",
                        fontSize: "14px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Search
                    </span>
                  </>
                ) : (
                  <i className="bi bi-globe fs-2 p-0"></i>
                )}
              </button>

              {!stores.companyStorageStore.selectedCompanyStorage &&
              !isElectron ? (
                <>
                  <Link to="/storages" className="btn btn-sm btn-light-warning">
                    <span className="fs-6 ms-2">
                      Please connect your storage
                    </span>
                  </Link>
                </>
              ) : stores.companyAppStore.companyApps.length === 0 &&
                !isElectron ? (
                <>
                  <Link
                    to="/connections"
                    className="btn btn-sm btn-light-warning"
                  >
                    <span className="fs-6 ms-2">Please connect an app</span>
                  </Link>
                </>
              ) : null}
            </div>

            <div>
              {!isAIReady && !stores.companyAvatarStore.isAvatarLoading ? (
                <button
                  className={`btn ${
                    !isAIReady ? "bg-gray-300 btn-btn-bg-light" : "btn-primary"
                  }`}
                  type="button"
                  data-kt-element="send"
                  onClick={() => {
                    this.handleStopChat();
                  }}
                  disabled={isAIReady}
                  style={{
                    backgroundColor: !isAIReady ? "#f1f1f2" : undefined,
                    borderColor: !isAIReady ? "#f1f1f2" : undefined,
                  }}
                >
                  {isAIReady ? "Send" : "Stop"}
                </button>
              ) : (
                <button
                  disabled={
                    !isAIReady ||
                    this.state.isVideoChatActive ||
                    (!selectedCompanyModel &&
                      !selectedCompanyAvatar &&
                      !canUseTheChief) ||
                    (selectedCompanyModel &&
                      this.state.selectedCompanyAppIds.length === 0)
                  }
                  className="btn btn-primary"
                  type="button"
                  data-kt-element="send"
                  style={{ width: "100px" }}
                  onClick={() => {
                    this.sendMessage();
                  }}
                >
                  Send
                </button>
              )}

              <VoiceChatModal
                isOpen={this.state.showVoiceChatModal}
                onClose={() => {
                  this.setState(
                    {
                      showVoiceChatModal: false,
                      isVoiceChatActive: false,
                    },
                    () => {
                      this.getMessages();
                    }
                  );
                  window.dispatchEvent(
                    new CustomEvent("voicechatModeChange", {
                      detail: { isVoiceChatActive: false },
                    })
                  );
                }}
                selectedMode={
                  this.props.canUseTheChief && !this.state.selectedCompanyAvatar
                    ? "chief"
                    : "avatar"
                }
                userDocument={this.state.userDocument}
                companyId={stores.companyStore.selectedUserCompany?.id ?? 0}
                companyAvatar={this.state.selectedCompanyAvatar}
                companyAvatarName={
                  this.state.selectedCompanyAvatar
                    ? this.state.selectedCompanyAvatar.name
                    : undefined
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}
