import { action, observable, when } from "mobx";

import { getDashboardSections } from "../../helpers/api";
import getSharedDashboardSectionsWithUser from "../../helpers/api/getSharedDashboardSectionsWithUser";
import { SharedDashboardSectionStatus } from "../../helpers/Enums";
import { DashboardSection } from "../models/DashboardSection";
import { SharedDashboardSection } from "../models/SharedDashboardSection";

import RootStore from "./RootStore";
import stores from ".";

export default class SectionStore {
  @observable isSharedSectionModalOpen: boolean = false;
  @observable selectedSection: DashboardSection | undefined = undefined;
  @observable sections: DashboardSection[] = [];
  @observable sharedDashboardSectionsWithUser: SharedDashboardSection[] = [];
  @observable sharedDashboardSectionInvitations: SharedDashboardSection[] = [];
  @observable loading: boolean = true;

  constructor(rootStore: RootStore) {
    when(
      () =>
        rootStore.companyStore.selectedUserCompany !== undefined &&
        rootStore.companyUserStore.isLoading === false,
      () => {
        this.fetchDashboardSections();
        this.fetchsharedDashboardSectionsWithUser();
      }
    );
  }

  @action
  async fetchDashboardSections() {
    try {
      this.loading = true;
      const sections = await getDashboardSections(
        Number(stores.userStore.currentUser.id)
      );
      this.sections = sections;
      this.loading = false;
    } catch (error) {
      console.error("Error fetching sections:", error);
      this.loading = false;
    }
  }

  @action
  async fetchsharedDashboardSectionsWithUser() {
    try {
      const sharedDashboardSectionsWithUser =
        await getSharedDashboardSectionsWithUser(
          stores.userStore.currentUser.id
        );
      this.sharedDashboardSectionsWithUser =
        sharedDashboardSectionsWithUser.filter(
          (section) =>
            section.invitation_status === SharedDashboardSectionStatus.Accepted
        );

      this.sharedDashboardSectionInvitations =
        sharedDashboardSectionsWithUser.filter(
          (section) =>
            section.invitation_status === SharedDashboardSectionStatus.Pending
        );
    } catch (error) {
      console.error("Error fetching shared dashboard sections:", error);
    }
  }
}
