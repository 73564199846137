/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";

import { KTSVG, toAbsoluteUrl } from "../../../helpers";
import { SharedDashboardSection } from "../../models/SharedDashboardSection";
import analytics from "../../../helpers/Analytics";

interface State {}

interface Props {
  onAcceptPress: (sharedSection: SharedDashboardSection) => void;
  onRejectPress: (sharedSection: SharedDashboardSection) => void;
  sharedSection: SharedDashboardSection;
}

@observer
export default class SharedDashboardSectionInvitationItem extends React.Component<
  Props,
  State
> {
  state: State = {};

  renderSectionStatusBadge = (status: string) => {
    let iconPath = "";
    let badgeClass = "";
    let labelText = "";

    switch (status) {
      case "Connected":
        iconPath = "/media/icons/duotune/general/gen048.svg";
        badgeClass = "text-primary";
        labelText = "Connected";
        break;
      case "InProgress":
        iconPath = "/media/icons/duotune/general/gen049.svg";
        badgeClass = "text-warning";
        labelText = "In Progress";
        break;
      case "Failed":
        iconPath = "/media/icons/duotune/general/gen050.svg";
        badgeClass = "text-danger";
        labelText = "Failed";
        break;
      default:
        iconPath = "/media/icons/duotune/general/gen051.svg";
        badgeClass = "text-danger";
        labelText = "Not Connected";
        break;
    }

    return (
      <span className={`${badgeClass} fs-8 ms-2 mb-1 fw-bold`}>
        <KTSVG
          path={iconPath}
          className={`${badgeClass} svg-icon svg-icon-3 me-1`}
        />
        {labelText}
      </span>
    );
  };

  render() {
    const { sharedSection, onAcceptPress, onRejectPress } = this.props;

    return (
      <div className="pb-4">
        <div
          key={sharedSection.id}
          className=" rounded d-flex align-items-center bg-light-primary px-5 py-4"
        >
          <div className="symbol symbol-60px me-1">
            <img
              src={toAbsoluteUrl("/media/icons/duotune/abstract/abs037.svg")}
              className="align-self-center"
              alt={sharedSection.dashboard_section.section_name}
            />
          </div>

          <div className="py-3 px-4 me-1 mb-3">
            <div className="d-flex">
              <span className="fs-6 text-gray-800 fw-bolder">
                {sharedSection.dashboard_section.section_name}
              </span>
            </div>

            <div className="text-gray-500">{`Shared by ${sharedSection.shared_by_user.name}`}</div>
          </div>

          <div className="ms-auto d-flex">
            <button
              type="button"
              className="btn btn-light-success me-2"
              onClick={() => {
                analytics.trackEvent(
                  "accept_shared_dashboard_section_pressed",
                  {
                    category: "dashboard_section_events",
                    label: "accept_shared_dashboard_section_button",
                  }
                );
                onAcceptPress(sharedSection);
              }}
            >
              Accept
            </button>

            <button
              type="button"
              className="btn btn-light-danger"
              onClick={() => {
                analytics.trackEvent(
                  "reject_shared_dashboard_section_pressed",
                  {
                    category: "dashboard_section_events",
                    label: "reject_shared_dashboard_section_button",
                  }
                );
                onRejectPress(sharedSection);
              }}
            >
              Reject
            </button>
          </div>
        </div>
      </div>
    );
  }
}
