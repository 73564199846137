/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/rules-of-hooks */
import React, { useCallback, useEffect, useRef, useState } from "react";
import SpeechPlayer from "../../../../../src/helpers/SpeechPlayer";
import {
  createDocumentMessage,
  chatGeneral,
  chatAvatar,
  updateDocumentMessage,
} from "../../../../helpers/api";
import Functions from "../../../../helpers/Functions";
import { toast } from "react-toastify";
import SocketHelper from "../../../../helpers/SocketHelper";
import { ChatPageLoading } from "../../ChatPage/loading/ChatPageLoading";
import { DocumentMessage } from "../../../models/DocumentMessage";
import { CompanyAvatar } from "../../../models/CompanyAvatar";
import LottieAnimation from "./LottieAnimation";
import { KTIcon } from "../../../../helpers";

import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";

interface VoiceChatModalProps {
  isOpen: boolean;
  onClose: () => void;
  selectedMode: "chief" | "avatar";
  userDocument?: any;
  companyId: number;
  companyAvatar?: CompanyAvatar;
  companyAvatarName?: string;
}

const VoiceChatModal: React.FC<VoiceChatModalProps> = ({
  isOpen,
  onClose,
  selectedMode,
  userDocument,
  companyId,
  companyAvatar,
  companyAvatarName,
}) => {
  const [isMicOpen, setIsMicOpen] = useState<boolean>(true);
  const [streamText, setStreamText] = useState<string>(""); // Final metin (SpeechPlayer için)
  const [interimMessage, setInterimMessage] = useState<string>(""); // Interim mesajlar için yeni state
  const [mode, setMode] = useState<"idle" | "listening" | "speaking">("idle");
  const [isGreeting, setIsGreeting] = useState<boolean>(false);
  const [isUserMessage, setIsUserMessage] = useState<boolean>(false);
  const [beepPlaying, setBeepPlaying] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isWaiting, setIsWaiting] = useState<boolean>(false); // Interim mesajların gösterileceği state

  const speechPlayerRef = useRef<SpeechPlayer>(null);
  const beepAudioRef = useRef<HTMLAudioElement>(
    new Audio("/media/sounds/beep.mp3")
  );

  const silenceTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const inactivityTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(
    null
  );
  const loadingTimeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const chatRequestInProgress = useRef<boolean>(false);
  const shouldRestartRecognition = useRef<boolean>(true);
  const accumulatedTextRef = useRef<string>("");

  const {
    transcript,
    listening,
    resetTranscript,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  if (!browserSupportsSpeechRecognition) {
    return <span>Your browser does not support speech recognition.</span>;
  }

  const removePartialOverlap = (
    accumulated: string,
    decoded: string
  ): string => {
    const MAX_OVERLAP = 50;
    const overlapRegion = accumulated.slice(-MAX_OVERLAP);
    for (let i = overlapRegion.length; i > 0; i--) {
      const endSegment = overlapRegion.slice(-i);
      if (decoded.startsWith(endSegment)) {
        return decoded.slice(i);
      }
    }
    return decoded;
  };

  const handleMicToggle = useCallback(() => {
    setIsMicOpen((prevState) => {
      const newState = !prevState;
      if (newState) {
        // Mikrofon açıldığında, dinlemeyi başlatın.
        SpeechRecognition.startListening({ continuous: false });
      } else {
        // Mikrofon kapandığında, dinlemeyi durdurun.
        SpeechRecognition.stopListening();
      }
      return newState;
    });
  }, []);

  const handleClose = useCallback(() => {
    SpeechRecognition.stopListening();
    if (speechPlayerRef.current) {
      speechPlayerRef.current.stopPlayback();
    }
    beepAudioRef.current.pause();
    beepAudioRef.current.currentTime = 0;
    setBeepPlaying(false);
    setStreamText("");
    setInterimMessage("");
    onClose();
  }, [onClose]);

  const handleVoiceChatClose = useCallback(() => {
    if (isOpen) {
      SpeechRecognition.stopListening();
      handleClose();
      setStreamText("");
      setInterimMessage("");
    }
  }, [isOpen, handleClose]);

  const handleInterrupt = useCallback(() => {
    setStreamText("");
    setInterimMessage("");
    if (speechPlayerRef.current && speechPlayerRef.current.isPlaying()) {
      speechPlayerRef.current.stopPlayback();
    }
    if (isOpen) {
      shouldRestartRecognition.current = true;
      SpeechRecognition.startListening({ continuous: false });
    }
    beepAudioRef.current.pause();
    beepAudioRef.current.currentTime = 0;
    setBeepPlaying(false);
  }, [isOpen]);

  const sendGreeting = useCallback(async () => {
    let greetings: string[] = [];
    if (selectedMode === "chief") {
      greetings = [
        "Hi! How's your day going so far?",
        "Hello! Hope you're having a great day—how can I assist you?",
        "Hi! How’s everything going with you today?",
        "Hey! What’s up? How can I help make your day better?",
        "Hi there! Hope your day’s been good—let me know how I can help!",
      ];
    } else if (selectedMode === "avatar") {
      const avatarName = companyAvatarName || "Your Avatar";
      greetings = [
        `Hello, I am ${avatarName} manager. Hope you're having a great day—how can I assist you?`,
        `Greetings, I am ${avatarName} manager. How can I help make your day better?`,
        `Hi! I am ${avatarName} manager. Hope your day’s been good—let me know how I can help!`,
      ];
    }
    const randomGreeting =
      greetings[Math.floor(Math.random() * greetings.length)];
    setStreamText(randomGreeting);
    setMode("speaking");
    setIsGreeting(true);
  }, [selectedMode, companyAvatarName]);

  const sendChatRequest = useCallback(async () => {
    if (chatRequestInProgress.current) return;
    chatRequestInProgress.current = true;
    shouldRestartRecognition.current = false;
    const transcriptToSend = transcript;
    setIsUserMessage(true);
    if (!transcriptToSend.trim()) {
      chatRequestInProgress.current = false;
      if (isOpen) {
        shouldRestartRecognition.current = true;
        SpeechRecognition.startListening({ continuous: false });
      }
      return;
    }
    setIsWaiting(true);
    setInterimMessage("Thinking...");

    beepAudioRef.current.play().catch(() => {});
    setBeepPlaying(true);

    try {
      SpeechRecognition.stopListening();
      if (!companyId) {
        toast.error("Selected company is not defined.");
        chatRequestInProgress.current = false;
        return;
      }

      await new Promise((resolve) => setTimeout(resolve, 3000));

      const encodedTranscript = encodeURIComponent(transcriptToSend);
      const newUserMessage = await createDocumentMessage(
        userDocument.id,
        undefined,
        companyAvatar?.id,
        undefined,
        Date.now(),
        encodedTranscript,
        ""
      );
      let chatResult;
      if (selectedMode === "chief") {
        chatResult = await chatGeneral(
          userDocument.id,
          newUserMessage.id,
          companyId,
          encodedTranscript,
          1000,
          false,
          undefined
        );
      } else if (selectedMode === "avatar" && companyAvatar) {
        chatResult = await chatAvatar(
          userDocument.id,
          newUserMessage.id,
          companyAvatar.company_id,
          companyAvatar.id,
          encodedTranscript,
          false,
          undefined
        );
      }
      if (chatResult) {
        const decodedMessage = Functions.decodeMessage(chatResult.message);
        const updatedMessage: DocumentMessage = {
          ...newUserMessage,
          message_reply: encodeURIComponent(chatResult.message),
          source_documents: chatResult.sourceDocs
            ? encodeURIComponent(chatResult.sourceDocs.join(","))
            : newUserMessage.source_documents,
          company_avatar_id: companyAvatar?.id,
        };
        await updateDocumentMessage(updatedMessage).catch(() => {});
        setIsWaiting(false);
        setInterimMessage("");
        setStreamText(decodedMessage);
        setMode("speaking");
        setIsGreeting(false);
        setIsUserMessage(false);
      }
    } catch (error) {
      toast.error("An error occurred while sending the chat request.");
    } finally {
      chatRequestInProgress.current = false;
      if (!streamText.trim() && isOpen) {
        shouldRestartRecognition.current = true;
        SpeechRecognition.startListening({ continuous: false });
      }
      resetTranscript();
    }
  }, [
    transcript,
    isOpen,
    companyId,
    companyAvatar,
    userDocument,
    selectedMode,
    streamText,
    resetTranscript,
  ]);

  const MAX_ACCUMULATED_LENGTH = 5000;
  const lastReceivedTextRef = useRef<string>("");

  const handleSocketMessage = useCallback(
    (message: any) => {
      try {
        if (
          isOpen &&
          message.processId === 1 &&
          userDocument &&
          userDocument.id === message.userDocumentId
        ) {
          const decodedText = Functions.decodeMessage(message.streamedResult);
          const purifiedChunk = removePartialOverlap(
            accumulatedTextRef.current,
            decodedText
          ).trim();
          if (purifiedChunk === lastReceivedTextRef.current) return;
          lastReceivedTextRef.current = purifiedChunk;
          accumulatedTextRef.current += purifiedChunk + " ";
          if (accumulatedTextRef.current.length > MAX_ACCUMULATED_LENGTH) {
            accumulatedTextRef.current = accumulatedTextRef.current.slice(
              -MAX_ACCUMULATED_LENGTH
            );
          }
          const sentenceRegex = /[^.!?]+[.!?]+/g;
          let match;
          while (
            (match = sentenceRegex.exec(accumulatedTextRef.current)) !== null
          ) {
            if (match && match.length > 0) {
              const sentence = match[0].trim();
              setStreamText((prev) => prev + " " + sentence);
              setMode("speaking");
              setIsGreeting(false);
            }
          }
          accumulatedTextRef.current = accumulatedTextRef.current.slice(
            sentenceRegex.lastIndex
          );
          sentenceRegex.lastIndex = 0;
        }
      } catch (error) {
        console.error("Error in handleSocketMessage:", error);
      }
    },
    [userDocument, isOpen]
  );

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isWaiting) {
      const messages = [
        "Reasoning...",
        "Stay focused...",
        "Preparing...",
        "Hold tight...",
        "Processing...",
        "Almost done...",
        "Finalizing...",
      ];
      let index = 0;
      intervalId = setInterval(() => {
        setInterimMessage(messages[index]);
        index = (index + 1) % messages.length;
      }, 6000);
    } else {
      setInterimMessage("");
    }
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isWaiting]);

  useEffect(() => {
    if (!isOpen) return;
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
    }
    silenceTimeoutRef.current = setTimeout(() => {
      if (transcript.trim()) {
        sendChatRequest();
      } else {
        if (isOpen) {
          shouldRestartRecognition.current = true;
        }
      }
    }, 3000);
    if (inactivityTimeoutRef.current) {
      clearTimeout(inactivityTimeoutRef.current);
      inactivityTimeoutRef.current = null;
    }
  }, [transcript, isOpen, sendChatRequest]);

  useEffect(() => {
    if (!isOpen) return;
    if (!inactivityTimeoutRef.current) {
      inactivityTimeoutRef.current = setTimeout(() => {
        handleClose();
      }, 30000);
    }
  }, [isOpen, handleClose]);

  useEffect(() => {
    if (listening) {
      setMode("listening");
    } else {
      if (!isUserMessage) {
        setMode("idle");
      }
    }
  }, [listening, isUserMessage]);

  useEffect(() => {
    if (isOpen) {
      if (speechPlayerRef.current) {
        speechPlayerRef.current.stopPlayback();
      }
      setStreamText("");
      setIsLoading(true);
      sendGreeting();
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
      loadingTimeoutRef.current = setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      shouldRestartRecognition.current = true;
    }
    SocketHelper.addMessageHandler(handleSocketMessage);
    window.addEventListener("voicechatClose", handleVoiceChatClose);
    return () => {
      SocketHelper.removeMessageHandler(handleSocketMessage);
      SpeechRecognition.stopListening();
      if (speechPlayerRef.current) {
        speechPlayerRef.current.stopPlayback();
      }
      beepAudioRef.current.pause();
      beepAudioRef.current.currentTime = 0;
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
      }
      if (silenceTimeoutRef.current) {
        clearTimeout(silenceTimeoutRef.current);
      }
      if (inactivityTimeoutRef.current) {
        clearTimeout(inactivityTimeoutRef.current);
      }
      window.removeEventListener("voicechatClose", handleVoiceChatClose);
    };
  }, [isOpen, sendGreeting, handleSocketMessage, handleVoiceChatClose]);

  const getAnimationSpeed = () => {
    if (beepPlaying) return 3;
    if (mode === "listening") return 1;
    if (mode === "speaking") return 5;
    return 1;
  };

  if (!isOpen) return null;

  const modalOverlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.8)",
    zIndex: 10000,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };

  const modalContentStyle: React.CSSProperties = {
    padding: "2rem",
    borderRadius: "8px",
    width: "90%",
    height: "90%",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    marginLeft: "2%",
  };

  return (
    <div style={modalOverlayStyle}>
      <div className="bg-body" style={modalContentStyle}>
        <style>
          {`
            @keyframes fadeInOut {
              0% { opacity: 0; transform: translateY(-10px); }
              50% { opacity: 1; transform: translateY(0); }
              100% { opacity: 0; transform: translateY(10px); }
            }
            .interim-message {
              animation: fadeInOut 6s infinite;
            }
          `}
        </style>
        <div
          style={{
            position: "absolute",
            left: "2vw",
            top: "0vh",
            opacity: 0.4,
            width: "95%",
            height: "95%",
            margin: "auto",
          }}
        >
          <LottieAnimation speed={getAnimationSpeed() / 1.1} />
        </div>
        <div style={{ width: "90%", height: "90%", margin: "auto" }}>
          <LottieAnimation speed={getAnimationSpeed()} />
        </div>
        <div
          style={{ position: "absolute", top: "-10000px", left: "-10000px" }}
        >
          {!isUserMessage && (
            <SpeechPlayer
              ref={speechPlayerRef}
              text={streamText}
              onPlaybackStart={() => {
                SpeechRecognition.stopListening();
                beepAudioRef.current.pause();
                beepAudioRef.current.currentTime = 0;
                setBeepPlaying(false);
                SpeechRecognition.stopListening();
              }}
              onPlaybackFinished={() => {
                const greetingText1 =
                  "Hello, I am TheChief. I am an AI created by Company DNA. How can we assist you?";
                if (isOpen) {
                  setIsMicOpen(true);
                  shouldRestartRecognition.current = true;
                  SpeechRecognition.startListening({ continuous: false });
                }
                if (isGreeting && streamText === greetingText1) {
                  setStreamText("");
                  setIsGreeting(false);
                }
              }}
            />
          )}
        </div>
        <div className="d-flex justify-content-center mb-3">
          {isWaiting && (
            <div
              className="interim-message"
              style={{
                position: "absolute",
                bottom: "90px",
                textAlign: "center",
                width: "100%",
                fontWeight: "bold",
                fontSize: "1.1rem",
                color: "#78829d",
              }}
            >
              {interimMessage}
            </div>
          )}
          <button
            style={{ zIndex: 999 }}
            className="btn btn-outline btn-color-gray-600 btn-outline-default"
            onClick={handleInterrupt}
          >
            Interrupt
          </button>
          <button
            style={{ zIndex: 999, marginLeft: "0rem" }}
            className="btn btn-color-gray-600 w-50px"
            onClick={handleMicToggle}
          >
            {isMicOpen ? (
              <i className="fa fa-microphone" aria-hidden="true"></i>
            ) : (
              <i className="fa fa-microphone-slash" aria-hidden="true"></i>
            )}
          </button>

          <button
            onClick={handleClose}
            style={{
              position: "absolute",
              top: "40px",
              right: "40px",
              background: "none",
              border: "none",
              cursor: "pointer",
            }}
          >
            <KTIcon iconName="cross" className="fs-1" />
          </button>
        </div>
        {isLoading && (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundColor: "rgba(0,0,0,0.8)",
              zIndex: 11000,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ChatPageLoading />
          </div>
        )}
      </div>
    </div>
  );
};

export default VoiceChatModal;
